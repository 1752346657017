import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { TbRefresh } from "react-icons/tb";
import { PostApi } from '../services/CommonService2';
import { Table } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Editor from "@monaco-editor/react";
const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  maxHeight: '600px',
  overflow:"scroll",
};
function AllSubmissions({problemContent}) {
  const [AllSubmissions, setAllSubmissions] = useState([])
  const [userCode,setUserCode]=useState("")
  const [codeModel,setCodeModel] = useState(false)
  useEffect(() => {
    getAllSubmissions()
  }, [])
  const getAllSubmissions = async () => {
    var tntId = await JSON.parse(localStorage.getItem('tID'))

    const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
console.log(userdata,"check user details")
    if (userdata != null) {
      const reqPrams = {
        pageno: -1,
        query: { problemid: problemContent.id }
      }
      const submissions = await PostApi(reqPrams, "USERSUBMISSIONSGET")
      if(submissions.status==200)
      setAllSubmissions(submissions.data)
    }
  }
  const viewUserCode=(code)=>{
    setUserCode(code);

    setCodeModel(true);
  }
  return (
    <>
     <Modal
        open={codeModel}
        onClose={()=>setCodeModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="large"
      >
        <Box sx={style2}>
       <Editor 
      height={`400px`}
       width={`100%`}
       value={userCode}
       />
          <div className='d-flex justify-content-end'>
           
            <button className='btn btn-danger mt-4' onClick={()=>setCodeModel(false)}>Close</button>

          </div>
        </Box>
      </Modal>
     
    
      <div className='d-flex justify-content-end'>
      
        <div onClick={()=>{getAllSubmissions()}} style={{cursor:"pointer"}}>
          <TbRefresh className='fs-1 d-inline' style={{ marginLeft: '10px' }}  /> <span className='fs-5 pt-1 d-inline-block'>Refresh</span>
        </div>
      </div>
      <div className='d-flex justify-content-center mt-5'>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>
          Time (IST)</th>
          <th>	Status</th>
          <th>User</th>
          <th>Lang</th>
          <th>Code</th>
        </tr>
      </thead>
      <tbody>
       {AllSubmissions.map(ele=>{
        return( <tr>
          <td>{ele.submissiontime}</td>
          <td>	Wrong</td>
          <td>{ele.username}</td>
          <td>{ele.language}</td>
          <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>viewUserCode(ele.code)}>View</td>
        </tr>)
       })}
       

      </tbody>
    </Table>
     </div>
   
        
    
    </>
  )
}

export default AllSubmissions