import React, { useEffect, useState } from 'react';
import HeaderTwo from '../../components/HeaderTwo';
import { Treebeard } from 'react-treebeard';
import { PostApi } from '../../services/CommonService2';
import 'rc-tree/assets/index.css';
import Tree, { TreeNode } from 'rc-tree';
import { Styles } from '../account/styles/account';
import { Container, Row, Col, Toast, Modal } from 'react-bootstrap';
import moment from "moment";
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import swal from 'sweetalert'; import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal1 from '@mui/material/Modal';
import { TailSpin } from 'react-loader-spinner';
import { IconButton, TextField } from '@mui/material';
import { IoCloseSharp } from "react-icons/io5";
import axios from 'axios';
let apiurl ="https://asseshub.com";
// let apiurl = "http://localhost:8080";




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    height: "250px",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
};
function UploadedProjects() {
    const [project, setProject] = useState([])
    const [modal, setModal] = useState(false)
    const [selectedData, setSelectedData] = useState({})
    const [logs, setLogs] = useState([])
    const [buildModal, setBuildModal] = useState(false)
    const [logsModal, setLogsModal] = useState(false)
    const [deployModal, setDeployModal] = useState(false)
    const [urlmodal, setUrlModal] = useState(false)
    const [url, setURL] = useState('');
    const [filedata, setFileData] = useState({});
    const [fileModal, setFileModal] = useState(false);
    const [file, setFile] = useState()
    const [fileName, setFileName] = useState("Upload Project");


    useEffect(() => {
        myprojects()

    }, [])

    const myprojects = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const sessiondetails = await localStorage.getItem(`userdata${tntId}`);
        const userdata = JSON.parse(sessiondetails)
        console.log("userdata", userdata);
        const reqdata = {
            pageno: '-1',
            query: { userid: userdata?.id }

        }
        const Response = await PostApi(reqdata, 'GETUPLOADPROJECTS')
        console.log("Response", Response.data);
        setProject(Response.data)
    }

    const treeData = {
        name: "root",
        toggled: true,
        children: [
            {
                name: 'parent',
                children: [
                    { name: 'child1' },
                    { name: 'child2' }
                ]
            },
            {
                name: 'loading parent',
                loading: true,
                children: []
            },
            {
                name: 'parent',
                children: [
                    {
                        name: 'nested parent',
                        children: [
                            { name: 'nested child 1' },
                            { name: 'nested child 2' }
                        ]
                    }
                ]
            }
        ]
    };

    const [data, setData] = useState(treeData);

    const onToggle = (node, toggled) => {
        if (node.children) {
            node.toggled = toggled;
        }
        setData(Object.assign({}, data));
    };

    const onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
    };
    const openModal = async (item) => {
        console.log("item", item);
        // setSelectedData(item)
        setModal(true)
    }

    const DeletePopUp = async (data) => {
        console.log("data", data);
        setSelectedData(data)
        setModal(true)



    }

    const DeleteProject = async () => {
        console.log(" delete data", selectedData);
        const deleteData = {
            filename: selectedData.projectname
        }

        const DeleteResponse = await PostApi(selectedData, 'DELETEZIPFILE')
        console.log("DeleteResponse", DeleteResponse);
        const Response = await PostApi(selectedData, "DELETEUPLOADPROJECTS")
        if (Response.data.id) {
            swal({
                icon: "success",
                title: "Project deleted successfully",
                closeOnClickOutside: false,
                closeOnEsc: false,
            })
            myprojects()
            setModal(false)

        }
    }

    const DeployProject = async (data) => {
        console.log("deploy data", data);
        setDeployModal(true)
        const DeployResponse = await PostApi(data, 'DEPLOYPROJECT')
        console.log("DeployResponse", DeployResponse);
        if (DeployResponse) {
            setLogs(DeployResponse.logs)
            setDeployModal(false)
            const regex = /Finished: SUCCESS/g;

            const matches = regex.test(DeployResponse.logs);

            // const matches = BuildResponse.logs.includes('Finished: SUCCESS');

            const warnregex = /INFO: Project has no build files. Reupload the project code to rebuild/;
            const warnmatches = warnregex.test(DeployResponse.logs);

            console.log("warnmatches", warnmatches);




            if (matches) {

                const urlRegex = /Project URL: (https:\/\/[\w.-]+)/;
                const match = DeployResponse.logs.match(urlRegex);
                console.log("match", match);
                if (match && match[1]) {
                    console.log("match[1]", match[1]);
                    console.log("data if matches", data);

                    const reqparams = {
                        projecturl: match[1],
                        // pageno: '-1',
                        id: data.id
                    }

                    console.log("reqparams", reqparams);
                    const response = await PostApi(reqparams, "UPDATEPROJECT")
                    console.log("response----", response);
                }
                swal({
                    icon: "success",
                    title: "Project Deployed successfully",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: {
                        seeLogs: {
                            text: "See Logs",
                            value: "seeLogs",
                        },
                        ok: {
                            text: "OK",
                            value: "ok",
                        },

                    },
                }).then((value) => {
                    if (value === "seeLogs") {
                        seeLogs();
                    }
                });


            } else if (warnmatches) {
                swal({
                    icon: "warning",
                    title: "Build folder does not exists",
                    text: "First build the project and then deploy",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                })


            } else {
                console.log('No match found');
                swal({
                    icon: "error",
                    title: "Project Deploy failed!",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: {
                        seeLogs: {
                            text: "See Logs",
                            value: "seeLogs",
                        },
                        ok: {
                            text: "OK",
                            value: "ok",
                        },

                    },
                }).then((value) => {
                    if (value === "seeLogs") {
                        seeLogs();
                    }
                });

            }
            myprojects()

        }

    }

    const BuildProject = async (data) => {
        setBuildModal(true)
        const BuildResponse = await PostApi(data, 'BUILDPROJECT')
        if (BuildResponse) {
            setLogs(BuildResponse.logs)
            setBuildModal(false)
            const regex = /Finished: SUCCESS/g;

            const matches = regex.test(BuildResponse.logs);

            // const matches = BuildResponse.logs.includes('Finished: SUCCESS');

            if (matches) {
                swal({
                    icon: "success",
                    title: "Project Build successfull",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });

            } else {
                console.log('No match found');
                swal({
                    icon: "error",
                    title: "Project Build failed!",
                    text: "Re Upload the project,make sure your project does not contain errors ",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: {
                        seeLogs: {
                            text: "See Logs",
                            value: "seeLogs",
                        },
                        ok: {
                            text: "OK",
                            value: "ok",
                        },

                    },
                }).then((value) => {
                    if (value === "seeLogs") {
                        seeLogs();
                    }
                });
            }


        }

    }

    const seeLogs = async () => {
        setLogsModal(true)

    }

    const getURL = async (data) => {
        setUrlModal(true)
        setURL(data)

    }

    const saveFile = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
            setFile(e.target.files[0]);
        } else {
            setFileName("Upload Project");
        }

    }

    const ReUploadFile = async (data) => {
        console.log("data", data);
        setFileModal(true)
        setFileData(data)


    }

    const uploadZipFile = async (data) => {
        if (file == '' || file == undefined) {
            toast.info("Please Upload a zip file", {
                position: "top-center",
                autoClose: 5000

            })

        }
        else if (file.name != filedata.projectfile) {
            toast.error("file name should match with uploaded zip file", {
                position: "top-center",
                autoClose: 5000
            })
        }
        else {

            const formData = new FormData();
            formData.append("file", file);
            formData.append("projectname", filedata.projectname)
            // const Response = await PostApi(formData, "UPLOADZIPFILE")
            // console.log("Response", Response);

            try {
                const res = await axios.post(
                    apiurl + "/api/v1/zipfile/upload",
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
                );
                console.log("res.data", res.data);
                toast.info("Uploaded zip file successfully", {
                    position: "top-center",
                    autoClose: 5000
                })
            } catch (ex) {
                console.log(ex);
            }

            setFile('')
            setFileName("Upload Project")
            setFileModal(false)

        }
        setFile('')
        setFileName("Upload Project")


    }
    return (
        <>
            <HeaderTwo />
            {/* <Treebeard
                data={data}
                onToggle={onToggle}
            /> */}
            <Styles>

                <section className="login-area">
                    <br />
                    <Container>
                        <Row>
                            <Col md="12">
                                {project.length > 0 ? <div className="uploadedprojects">
                                    <h3 style={{color:"#000",fontFamily:"ui-monospace",fontWeight:"bold"}}>Uploaded Projects</h3>
                                    <br />
                                    <form id="form_login" className="form">


                                        {project?.map((item) => {
                                            return <>
                                                <Row>
                                                    <Col>
                                                        <Tree onSelect={onSelect}>
                                                            <TreeNode title={item.projectname} key="0-0" style={{ fontSize: "20px" }}>
                                                                {/* <TreeNode title="Child 1" key="0-0-0" /> */}
                                                                <TreeNode title={<span>{moment(item.createddate).format('YYYY-MM-DD')}</span>}>
                                                                    {/* <TreeNode title="Grandchild" key="0-0-1-0" /> */}
                                                                </TreeNode>
                                                            </TreeNode>
                                                        </Tree>
                                                    </Col>
                                                    <Col style={{ textAlign: "center" }}>
                                                        <button type="button" class="btn btn-dark btn-lg" style={{ marginRight: "8px", }} onClick={() => DeletePopUp(item)}>Delete</button>
                                                        <button type="button" class="btn btn-secondary btn-lg" style={{ marginRight: "8px" }} onClick={() => BuildProject(item)}>Build</button>
                                                        <button type="button" class="btn btn-light btn-lg" onClick={() => DeployProject(item)} style={{ marginRight: "8px", backgroundColor: "#a9a9a9" }}>Deploy</button>

                                                        <button type="button" class="btn btn-light btn-lg" onClick={() => ReUploadFile(item)} style={{ marginRight: "8px",backgroundColor:"#d3d3d3" }}>Re Upload</button>

                                                        {item.projecturl != '' && <button type="button" class="btn btn-light btn-lg" style={{ marginRight: "8px" ,backgroundColor:"#e5e4e2",color:"#000"}} onClick={() => getURL(item)}>URL</button>}

                                                    </Col>

                                                </Row>
                                                <br />


                                            </>



                                        })}



                                    </form>





                                </div> : <div className="noprojects" style={{height:"550px"}}>
                                    {/* <h3>No Projects Found</h3> */}
                                    <p className='projectsmsg'>No Projects Found!</p>
                                </div>}
                            </Col>

                        </Row>

                    </Container>

                </section>

            </Styles>

            <Modal size="m" show={modal}>
                <form >
                    <div className="modal-header">

                        <button type="button" className="btn-close" onClick={() => { setModal(false) }} data-dismiss="modal"></button>
                    </div>
                    <div className="modal-body text-center">

                        <div class="card" >
                            <div class="card-body">
                                {/* <h5 class="card-title">Project Name:{selectedData.projectname}</h5> */}
                                <h6 class="card-subtitle mb-2 text-muted">Are you sure you want to delete project permanently?</h6>
                                <br />
                                <button type="button" class="btn btn-dark btn-lg" style={{ marginRight: "8px", }} onClick={DeleteProject}>Delete</button>
                                <button type="button" class="btn btn-secondary btn-lg" style={{ marginRight: "8px" }} onClick={() => setModal(false)}>Cancel</button>
                            </div>
                        </div>

                    </div>
                </form>


            </Modal>

            {buildModal &&
                <div className="custom-modal">
                    <Modal1
                        open={buildModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>

                            <TailSpin
                                visible={true}
                                height="80"
                                width="80"
                                color="#4fa94d"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: "20px", fontFamily: "sans-serif", fontWeight: 'bold', color: '#333', }}>
                                Build is in progress
                            </Typography>
                        </Box>
                    </Modal1>
                </div>}

            {deployModal &&
                <div className="custom-modal">
                    <Modal1
                        open={deployModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>

                            <TailSpin
                                visible={true}
                                height="80"
                                width="80"
                                color="#4fa94d"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: "20px", fontFamily: "sans-serif", fontWeight: 'bold', color: '#333', }}>
                                Deploy is in progress
                            </Typography>
                        </Box>
                    </Modal1>
                </div>}

            {logsModal && <Modal size="xl" show={logsModal}>
                <div className="modal-header">

                    <button type="button" className="btn-close" onClick={() => { setLogsModal(false) }} data-dismiss="modal"></button>
                </div>
                <div className="modal-body">
                    <h4 className='text-center'>Logs</h4>
                    <p>{logs}</p>
                </div>
            </Modal>}

            {fileModal &&
                <div className="custom-modal">
                    <Modal1
                        open={fileModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <IconButton
                                aria-label="close"
                                onClick={() => setFileModal(false)}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <IoCloseSharp />
                            </IconButton>

                            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: "20px", fontFamily: "ui-monospace", color: '#333', }}>
                                Project Name: {filedata?.projectname}

                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: "16px", fontFamily: "ui-monospace", color: '#333', }}>
                                File Name: {filedata?.projectfile}

                            </Typography>
                            <Button
                                variant="outlined"
                                component="label"
                                sx={{ mt: 2 }}
                            >
                                {fileName}
                                <input
                                    type="file"
                                    hidden
                                    accept=".zip"
                                    onChange={saveFile}
                                />
                            </Button>
                            <Typography variant="body2" sx={{ mt: 1 }} style={{ fontSize: "14px" }}>
                                Note: Upload .zip file
                            </Typography>
                            <br />

                            <Button variant="contained" sx={{
                                position: 'absolute',
                                right: 15,
                                bottom: 15,
                            }} onClick={() => uploadZipFile(filedata)}>Upload</Button>


                        </Box>
                    </Modal1>
                </div>}

            <Modal size="m" show={urlmodal}>
                <form >
                    <div className="modal-header">

                        <button type="button" className="btn-close" onClick={() => { setUrlModal(false) }} data-dismiss="modal"></button>
                    </div>
                    <div className="modal-body text-center">

                        <div class="card" >
                            <div class="card-body">
                                {/* <h6 class="card-subtitle mb-2 text-muted">This is the URL for {url.projectname}</h6>
                                <br /> */}
                                <h6>Project URL:{url.projecturl}</h6>
                                {/* <a>{url.projecturl}</a> */}
                                <br />

                                <button type="button" class="btn btn-dark btn-lg" style={{ marginRight: "8px", }} onClick={() => setUrlModal(false)}>Ok</button>
                                <button type="button" class="btn btn-secondary btn-lg" style={{ marginRight: "8px" }} onClick={() => setUrlModal(false)}>Cancel</button>
                            </div>
                        </div>

                    </div>
                </form>


            </Modal>



        </>
    )
}

export default UploadedProjects