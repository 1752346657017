
import React, { Component, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../../components/HeaderTwo';
// import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import CourseSidebar from './CourseSidebar';
import CourseItemList from './CourseItemsList';
import FooterTwo from '../../../components/FooterTwo';
import { Styles } from '../styles/course.js';
import { PostApi } from '../../../services/CommonService2';
import MyProgramsItemList from './MyProgramsItemList.js';
import CourseSearch from './CourseSearch.js';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import Footer3 from '../../../home3components/Footer3.js';
import Header3 from '../../../home3components/Header3.jsx';


const MyProgramsNew = (props) => {
    const [categoryData, setCategoryData] = useState([])
    const [searchData, setSearchData] = useState([])
    const [courseList, setCourseList] = useState([]);
    const [course, setCourse] = useState([]);
    const [category, setCategory] = useState([]);
    const [updatedCategory, setUpdatedCategory] = useState([])
    const [dispAllPg, setDispAllPg] = useState(true);
    const [count, setCount] = useState(1);
    const [user, setUser] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [tenantId,setTenantId] = useState('')
    const [loading, setLoading] = useState(true)

   
    const location=useLocation();
    useEffect(()=>{
        if(courseList!=undefined){
            // setCurrentPage(1)
            setSearchData([])
        }
        
    },[location])

    useEffect(() => {
        var tntId=  JSON.parse(localStorage.getItem('tID'))
        setTenantId(tntId)
        getData()
        localStorage.removeItem('previewtype')
      
    }, [])

    useEffect(() => {
        getData()

    }, [searchData])



    const getData = async () => {
  var tntId= await JSON.parse(localStorage.getItem('tID'))

        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))

        if (userdata != null) {
            setUser(userdata.user.id)
            var reqparams1 = '';
            if (searchData == '' || searchData == undefined) {

                reqparams1 = {
                    pageno:"-1",
                    query: { userid: userdata.user.id },
                    ptype: 'PROGRAMPURCHASES'
                }
            }
            else {
                reqparams1 = {
                    pageno: "-1",
                    query: { userid: userdata.user.id, name: { $regex: searchData, $options: "i" }, status: 'Active' },
                    ptype: 'PROGRAMPURCHASES'
                }

            }
            // var rawData = []
            // var response = {}
            // reqparams1.pageno = 1
            // do {

            //     response = await PostApi(reqparams1, "DASHOARDLABEL");
            //     reqparams1.pageno += 1
            //     rawData.push(...response.data)
            // } while (response.data.length != 0);
            // console.log("response", response);

    

            // setTotal(rawData.length)


            // reqparams1.pageno = undefined


            const response = await PostApi(reqparams1, 'DASHOARDLABEL');
            setLoading(false)
            setCourseList(response.data);

            // console.log("mycourses data", response);

        }
    }

    // useEffect(()=>{
    //     const newData=async()=>{
    //         const userdata = JSON.parse(localStorage.getItem('userdata'))

    //         if(userdata!=null){
    //             var reqparams1='';
    //             if (searchData == '' || searchData == undefined) {

    //              reqparams1 = {
    //                     pageno: currentPage,
    //                     // page:currentPage,
    //                     query: { userid: userdata.user.id },
    //                     ptype: 'PROGRAMPURCHASES'
    //                 }
    //             }
    //             else{
    //                 reqparams1 = {
    //                     pageno: currentPage,
    //                     // page:currentPage,
    //                     query: { userid: userdata.user.id, name: { $regex: searchData, $options: "i" },status: 'Active' },
    //                     ptype: 'PROGRAMPURCHASES'
    //                 }

    //             }
    //             const response = await PostApi(reqparams1,'DASHOARDLABEL');

    //             setCourseList(response.data);

    //         }


    //     }
    //     newData()
    // },[currentPage])







    return (

        <div className="main-wrapper course-page">

            {/* Header 2 */}
            {tenantId == "ffe21ec9" ? <Header3 /> : <HeaderTwo />}

            {/* Breadcroumb */}
            {/* <BreadcrumbBox title="Programs" /> */}

            <Styles>
                {/* Course Grid */}
                <section className="course-list-area ">
                    <Container>
                        <Row>
                            {/* <Col lg="3" md="4" sm="5">
                                <CourseSidebar updatedCategory={updatedCategory}
                                    setSearchData={setSearchData}
                                    ctgFn={pgctg}
                                    dispAllPg={dispAllPg} />
                            </Col> */}
                            {/* <Col lg="9" md="8" sm="7"> */}
                            <Col lg="9" md="8" sm="7">
                                <div className="course-items2">
                                    <Row>

                                        <MyProgramsItemList courseList={courseList} currentPage={currentPage} setCurrentPage={setCurrentPage} total={total} searchData={searchData} setLoading={setLoading} loading={loading}/>
                                    </Row>
                                </div>
                            </Col>
                            <Col>
                                <CourseSearch setSearchData={setSearchData} />
                            </Col>
                            
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            {tenantId == "ffe21ec9" ? <Footer3 />:<FooterTwo/>}

        </div>
    )
}


export default MyProgramsNew