import React, { useEffect, useState } from 'react'
import { Styles } from '../account/styles/account'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col, Toast } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { PostApi } from '../../services/CommonService2';
import axios from 'axios';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
let apiurl ="https://asseshub.com";
// let apiurl = "http://localhost:8080";


function UploadProjects() {
    const [frontendtech, setfrontendtech] = useState('');
    const [backendtech, setbackendtech] = useState('');
    const [foldername, setfoldername] = useState('');
    const [projectname, setprojectname] = useState('');
    const [userdata, setUserData] = useState({});
    const [filefields, setFileFields] = useState(null);
    const [imageShow, setImageshow] = useState(false);
    const [file, setFile] = useState()
    const [fileName, setFileName] = useState("Upload Project");
    const [pdf,setPdf]=useState();
    const [message,setMessage] = useState("")
    const [msg,setMsg]=useState(false)
    const data = useLocation();

    useEffect(() => {
console.log("dataaaaa",data);
        loaddata();

    }, []);

    

    const loaddata = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const sessiondetails = await localStorage.getItem(`userdata${tntId}`);
        if (sessiondetails != null) {
            //const constuserdetails = JSON.parse(sessiondetails);
            setUserData(JSON.parse(sessiondetails));

        }
    }

    const saveFile = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
            setFile(e.target.files[0]);
        } else {
            setFileName("Upload Project");
        }

    }

    const handleChange = (e) => {
        const { value } = e.target;
        const regex = /^[A-Za-z0-9]*$/; // Regular expression to allow only letters and underscore
    
        if (regex.test(value)) {
          setprojectname(value);
          setMessage('');
        
        } else {
            setMsg(true)
          setMessage('Only Numeric, capital, and small letters are allowed.');
        }
      };

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append("file",file);
        formData.append("projectname",projectname.toLowerCase())
        // for (let pair of formData.entries()) {
        //     console.log(pair[0] + ', ' + pair[1]);
        // }
        try {
            const res = await axios.post(
                apiurl + "/api/v1/zipfile/upload",
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
            }
            );
            setPdf(res.data)
        } catch (ex) {
            console.log(ex);
        }

    }
    const submitForm = async (e) => {
       
        e.preventDefault()

        if (projectname == '' ||fileName=="Upload Project") {
            toast.error('Please fill all fields', {
                position: "top-center",
                autoClose: 5000
            })
        }
        else {
            uploadFile()

            const reqdata = {
                userid: userdata.id,
                projectname: projectname.toLowerCase(),
                frontendtech: data?.state.skills,
                tenantid: userdata?.tenant?.tenantid,
                username: userdata?.user?.name,
                // backendtech: backendtech,
                projectfile: fileName,
                projecturl:""
                // tenantid:""


            }

            console.log("student reqdata", reqdata);

            const Response = await PostApi(reqdata, 'SAVEUPLOADPROJECTS');
            console.log("Response", Response);
            if (Response?.data.id) {
                toast.info('data saved succesfully', {
                    position: "top-center",
                    autoClose: 5000

                })

            }
            setprojectname('')
            setfrontendtech('')
            setbackendtech('')
            setFileName("Upload Project")

        }






    }

    return (
        <>
            <HeaderTwo />
            <Styles>
                <section className="login-area">
                    <br />
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="login-box">
                                    <h3>Create Project</h3>

                                    <form id="form_login" className="form" >
                                        <br />


                                        <p className="form-control">
                                            <label htmlFor="Title">Technology</label>
                                            <input type="text" placeholder="frontend tech" id="Title" name='title' value={data?.state?.skills}  />

                                            {/* <select
                                                className="form-control" name='title'
                                                onChange={(e) => setfrontendtech(e.target.value)} value={frontendtech}>
                                                <option value="">Select </option>
                                                <option value="React">React</option>
                                                <option value="Angular">Angular</option>
                                            </select> */}
                                        </p>
                                        {/* <p className="form-control">
                                            <label htmlFor="Title">Backend Technology*</label>
                                            <select
                                                className="form-control" name='title'
                                                onChange={(e) => setbackendtech(e.target.value)} value={backendtech}>
                                                <option value="">Select </option>
                                                <option value="Node">Node</option>
                                                <option value="Python">Python</option>
                                            </select>
                                            <span className="login_input-msg"></span>
                                        </p> */}

                                      

                                        <p className="form-control">
                                            <label htmlFor="Title">Project Name*</label>
                                            <input type="text" placeholder="project name" id="Title" name='title' value={projectname} onChange={handleChange} />
                                            {msg && <p style={{ color: 'red' }}>{message}</p>}
                                        </p>

                                        {/* <label htmlFor="Title" for="myfile">Select Project:</label>
                                        <input type="file" accept='.zip' onChange={saveFile} /> */}

                                        <label htmlFor="file-upload" className="custom-file-upload">
                                            {fileName}
                                        </label>
                                        <input
                                            id="file-upload"
                                            type="file"
                                            accept=".zip"
                                            onChange={saveFile}
                                            style={{ display: 'none' }}
                                        />
                                        <br />

                                        <span>Note: Upload .zip file</span>

                                        <button style={{ marginTop: "20px" }} onClick={submitForm}>Create Project</button>
                                    </form>
                                </div>
                            </Col>

                        </Row>

                    </Container>

                </section>
            </Styles>
        </>
    )
}

export default UploadProjects