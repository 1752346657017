import React, { Component, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb.js';
import FooterTwo from '../../components/FooterTwo.js';
import StateItemGrid from './components/StateItemGrid.js';
import { Styles } from './styles/course';
import StateSidebar from './components/StateSidebar.js';
import StateCategory from './components/StateCategory.js';
import { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const StateClass=()=>{

    const [searchData,setSearchData]=useState("")
    const [stateData,setStateData]=useState([])
    const location = useLocation();
    const [data,setdata]=useState([])

    useEffect(()=>{
        localStorage.removeItem('previewtype')
    },[]);

    

    useEffect(()=>{
        setStateData([])
        setSearchData("")
    },[location])

        return (
            <div className="main-wrapper course-page">
                {/* {console.log("course data",data)} */}
                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                {/* <BreadcrumbBox title="Courses" /> */}

                {/* <Styles> */}
                    {/* Course Grid */}
                    <section className='p-4' >
                     
                            <Row>
                                {/* {console.log(data,"check the data pod i dont know")} */}
                                
                                {/* {data?.length>0 && <Col lg="3" md="4" sm="5">
                                   <StateSidebar setSearchData={setSearchData} />
                                </Col>} */}
                                
                               
                               
                            </Row>
                            <div className='d-flex justify-content-center mb-5'>
                   
                    </div>
                            <Row>
                            <Col lg="12" md="8" sm="7">
                                    
                                    <Row>
                                   <StateCategory setSearchData={setSearchData}   setStateData={setStateData} />
                                    </Row>
                                
                            </Col>
                           <Col lg="12"  sm="7">
                                    
                                    <Row className='p-5'>
                                    <StateItemGrid setSearchData={setSearchData} searchData={searchData} stateData={stateData} setdata={setdata}
                                     />
                                    </Row>
                                
                            </Col>
                          

                            </Row>
                      
                    </section>
                {/* </Styles> */}

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        )
    

}

export default StateClass