import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import FooterTwo from '../../components/FooterTwo';
import HeaderTwo from '../../components/HeaderTwo';
import Footer3 from '../../home3components/Footer3.js';
import Header3 from '../../home3components/Header3.jsx';
import { PostApi } from '../../services/CommonService2';
import CourseItemList from './components/CourseItemsList';
import CourseSidebar from './components/CourseSidebar';
import { Styles } from './styles/course.js';



const CourseList = () => {
    const [courseList, setCourseList] = useState([]);
    const [course, setCourse] = useState([]);
    const [category, setCategory] = useState([]);
    const [updatedCategory, setUpdatedCategory] = useState([])
    const [tenantId,setTenantId] = useState('')
    const [loading,setLoading]=useState(true)
    const [programPreview,setProgramPreview]=useState([])


    const didMountRef = useRef(false);
    const didMountRef2 = useRef(false);

    const [categorySelect, setCategorySelect] = useState([])

    const [searchKey, setSearchKey] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(1)
    const [totalRecords, setTotalRecords] = useState(null)
    const [programDuration,setProgramDuration] = useState([])
    const location = useLocation();


    useEffect(() => {
//   if(location?.state?.name !="") {
//     searchBar(location?.state?.name)
//   }else{
//     searchBar("")
//     setSearchKey("")
//   }
        if (course.length != 0) {

            setCategorySelect([])
        }
        setSearchKey("")
       

    }, [location])



   
    useEffect(() => {
        setLoading(true)
        getCourses();
        getCategory();
        getAllCourse();
    }, [])

    useEffect(() => {

        let arr = category.map((item) => {
            return {
                ...item,
                check: false
            }
        })

        // console.log("arr mate", arr);
        setUpdatedCategory(arr)
        // setUpdatedCategory2(arr)

    }, [category])





    const getCourses = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        setTenantId(tntId)
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        var reqparam = {
        }

        if (userdata?.user.subapplicationid ) {
            if (location?.state?.name != undefined) {
                reqparam = {
                    pageno: 1,
                    query: { status: 'Active',category: {$nin: ["656f5590373b3a372764c0f3","65991d82e122a2663cd2ef07" ]} },
                    usertype: 'APP',
                    tenantid: '',
                    optimize: true,
                    userid: ""
                }
                reqparam.query.name = { $regex: location.state.name, $options: "i" }
            }
            else {
                reqparam = {
                    pageno: 1,
                    query: {
                        status: 'Active',
                        area: { $elemMatch: { "value": userdata?.user.subapplicationid } },
                        category: {$nin: ["656f5590373b3a372764c0f3","65991d82e122a2663cd2ef07" ]}
                    },
                    usertype: 'APP',
                    tenantid: '',
                    optimize: true,
                    userid: ""
                }
            }

        } else {

            if (location?.state?.name != undefined) {
                reqparam = {
                    pageno: 1,
                    query: { status: 'Active',category: {$nin: ["656f5590373b3a372764c0f3","65991d82e122a2663cd2ef07" ]} },
                    usertype: 'APP',
                    tenantid: '',
                    optimize: true,
                    userid: ""
                }
                reqparam.query.name = { $regex: location.state.name, $options: "i" }
            }
            else {
                reqparam = {
                    pageno: 1,
                    query: { status: 'Active',category: {$nin: ["656f5590373b3a372764c0f3","65991d82e122a2663cd2ef07" ]} },
                    usertype: 'APP',
                    tenantid: '',
                    optimize: true,
                    userid: ""
                }
            }
        }
        const action = "ITPROGRAMS"
        // console.log(reqparam.query.name ,"chek the name of the Tcs program")
        const ResponseData = await PostApi(reqparam, action);
        setCourseList(ResponseData.data);
        setTotalRecords(ResponseData.total)
      
            var programids =[]
            for(var s=0;s<ResponseData.data.length;s++){
              programids[s]=ResponseData.data[s].id
            }
             const ResponseDuration = await PostApi(programids, "PROGRAMDURATION");
             const ResponsePreview = await PostApi(programids, "PROGRAMPREVIEW");
             
             setProgramPreview(ResponsePreview?.data)
            setProgramDuration(ResponseDuration?.data);
    }

    const getAllCourse = async () => {
        const reqparam = {
            pageno: currentPage,
            // page:currentPage,
            query: { status: 'Active',category: {$nin: ["656f5590373b3a372764c0f3","65991d82e122a2663cd2ef07" ]} },
            usertype: 'APP',
            tenantid: '',
            optimize: true,
            userid: ""
        }
        const action = "ITPROGRAMS"
        const ResponseData = await PostApi(reqparam, action);
        console.log(ResponseData, "ResponseData initial courses")

        setCourse(ResponseData.data)
        setCount(ResponseData)
    }

    const getCategory = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        let reqparam = '';
        if (userdata?.user.subapplicationid ) {
            reqparam = {
                pageno: '-1',
                query: {
                    status: 'Active',
                    area: {
                        $elemMatch: { "value": userdata?.user.subapplicationid }
                    },
                    _id:{$ne:{$in:["656f5590373b3a372764c0f3","6683df6b249e0258296ca2f2","660b90ebfa95f0377f1fe2dc","65991d82e122a2663cd2ef07"]}}
                }
            }

        }
        else {
            reqparam = {
                pageno: '-1',
                query: { status: 'Active',_id:{$nin:["656f5590373b3a372764c0f3","6683df6b249e0258296ca2f2","660b90ebfa95f0377f1fe2dc","65991d82e122a2663cd2ef07"]} }
            }

        }

        const action = "CATEGORY"

        const ResponseData = await PostApi(reqparam, action);
        console.log(ResponseData,"ResponseData get categories")
        setCategory(ResponseData.data)

    }




    async function ctgSearch(e, data) {
        if (e == "All") {
            setCategorySelect([])
            setSearchKey("")
        } else {
            const { checked } = e.target

            if (checked) {
                setSearchKey("")
                if (categorySelect.length < 0) {
                    setCategorySelect([data])
                }

                else {
                    setCategorySelect((prevState) => {
                        return [...prevState, data]
                    })
                }
            }
            else {
                setCategorySelect(categorySelect.filter((item) => item != data))
            }
        }


    }

    useEffect(() => {
        if (didMountRef.current) {

            const categorySelectCheck = categorySelect.filter(t => t != null);
            var tntId = JSON.parse(localStorage.getItem('tID'))
            const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))

            const ctgbasedsearch = async () => {
                let reqparams1 = ''

                if (userdata?.user.subapplicationid ) {
                    reqparams1 = {
                        pageno: 1,
                        query: { status: 'Active', area: { $elemMatch: { "value": userdata?.user.subapplicationid } } },
                        optimize: true,
                        userid: '',
                        usertype: 'APP',
                        tenantid: ''
                    }

                }
                else {
                    reqparams1 = {
                        pageno: 1,
                        query: { status: 'Active' },
                        optimize: true,
                        userid: '',
                        usertype: 'APP',
                        tenantid: ''
                    }

                }


                reqparams1.query.category = categorySelectCheck;
                console.log("reqparams1", reqparams1);
                const response = await PostApi(reqparams1, 'ITPROGRAMS');
                console.log("response", response.data);

                if (response.data.length > 0) {

                    setCourseList(response.data);
                    setTotalRecords(response.total)
                    setCurrentPage(1);
                } else {
                    setCourseList([]);
                    setTotalRecords(0)
                    setCurrentPage(1)
                }
                // setCourse(response.data)
                const updatedTypesWithCheck = updatedCategory.map((type) => ({
                    ...type,
                    check: categorySelect.some((categoryId) => categoryId === type.id),
                }));

                setUpdatedCategory(updatedTypesWithCheck)


            }

            if (categorySelectCheck.length != 0) {
                ctgbasedsearch()
                // console.log("serching")
            }
            else {

                if (searchKey == '') {
                    setCourseList(course)
                    setTotalRecords(count.total)
                    setCurrentPage(1)


                    const updatedTypesWithCheck = updatedCategory.map((type) => ({
                        ...type,
                        check: false,
                    }));

                    setUpdatedCategory(updatedTypesWithCheck)

                }
            }

        }
        else {
            didMountRef.current = true;
        }
    }, [categorySelect])

    //search function

    const searchBar = async (keySearch) => {
       setLoading(true)
        setSearchKey(keySearch)

        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        let reqparam = '';
        if (userdata?.user.subapplicationid ) {
            reqparam = {
                pageno: 1,
                query: {
                    status: 'Active',
                    area: { $elemMatch: { "value": userdata?.user.subapplicationid } }
                },
                usertype: 'APP',
                tenantid: '',
                optimize: true,
                userid: ""

            }

        }
        else {
            reqparam = {
                pageno: 1,
                query: { status: 'Active' },
                usertype: 'APP',
                tenantid: '',
                optimize: true,
                userid: ""

            }

        }
        
        reqparam.query.name = { $regex: keySearch, $options: "i" }
        const ResponseData = await PostApi(reqparam, "ITPROGRAMS");
        if (ResponseData?.data?.length > 0) {
            setLoading(false)
            console.log(ResponseData?.data, "ResponseData?.data ResponseData?.data")
            setCourseList(ResponseData.data);
            setTotalRecords(ResponseData.total)

            setCategorySelect([])
        } else {
            setLoading(false)
            setCourseList([]);
            setTotalRecords(0)

        }

        setCurrentPage(1)
        const updatedTypesWithCheck = updatedCategory?.map((type) => ({
            ...type,
            check: false,
        }));

        setUpdatedCategory(updatedTypesWithCheck)
    }

    useEffect(() => {

        const newData = async () => {
            var tntId = JSON.parse(localStorage.getItem('tID'))
            const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
            let reqparam = ''

            if (userdata?.user.subapplicationid ) {
                reqparam = {
                    pageno: currentPage,
                    query: { status: 'Active', area: { $elemMatch: { "value": userdata?.user.subapplicationid } },category: {$nin: ["656f5590373b3a372764c0f3","65991d82e122a2663cd2ef07" ]}  },
                    usertype: 'APP',
                    tenantid: '',
                    optimize: true,
                    userid: ""

                }

            }
            else {
                reqparam = {
                    pageno: currentPage,
                    query: { status: 'Active',category: {$nin: ["656f5590373b3a372764c0f3","65991d82e122a2663cd2ef07" ]} },
                    usertype: 'APP',
                    tenantid: '',
                    optimize: true,
                    userid: ""

                }

            }

            const action = "ITPROGRAMS"
            if (categorySelect.length != 0) {
                reqparam.query.category = categorySelect;
            }
           
            else if (searchKey != "") {
          
                reqparam.query.name = { $regex: searchKey, $options: "i" }

            }

            const ResponseData = await PostApi(reqparam, action);
            setCourseList(ResponseData.data);
            setTotalRecords(ResponseData.total)


        }
        // console.log("didMountRef.current",didMountRef.current);
        if (didMountRef2.current) {
            newData();
        }
        else {
            didMountRef2.current = true;
        }




    }, [currentPage])

    return (

        <div className="main-wrapper course-page">

            {/* Header 2 */}
{tenantId=="ffe21ec9" ? <Header3/> : <HeaderTwo/>}
            {/* Breadcroumb */}
            {/* <BreadcrumbBox title="Programs" /> */}

            <Styles>
                {/* Course Grid */}
                <section className="course-list-area ">
                    <Container>
                        <Row>
                            <Col lg="3" md="4" sm="5">
                                <CourseSidebar updatedCategory={updatedCategory}
                                    // setSearchData={setSearchData}
                                    searchBar={searchBar}
                                    ctgFn={ctgSearch}
                                />
                            </Col>
                            <Col lg="9" md="8" sm="7">
                                <div className="course-items2">
                                    <Row>
                                        {/* {console.log("actual data",categoryData)} */}
                                        <CourseItemList
                                            courseList={courseList}
                                            course={course}
                                            totalRecords={totalRecords}
                                            setCurrentPage={setCurrentPage}
                                            currentPage={currentPage}
                                            loading={loading}
                                            durations={programDuration}

                                        />
                                        {/* currentPage={currentPage} */}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            {tenantId=="ffe21ec9" ? <Footer3 /> :       <FooterTwo />}

        </div>
    )
}


export default CourseList