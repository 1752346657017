import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import MainContainer from './MainContainer'
import HeaderTwo from '../components/HeaderTwo'
import FooterTwo from '../components/FooterTwo'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
function CodingHome() {
  var [subTopicSelectedTags,setSetSubTopicSelectedTags] = useState([]);
  var [companySelectedTags,setCompanySelectedTags] = useState([]);
  var [searchBarData,setSearchBarData] = useState("");
  var [level,setLevel] = useState([])
  const companylocationvalue = useLocation();
 
  return (
 <>
 <HeaderTwo />
    <div className='row p-0 m-0' style={{backgroundColor:"#3b444b"}}>
<div className='col-lg-3'>
<SideBar  setSetSubTopicSelectedTags={setSetSubTopicSelectedTags} subTopicSelectedTags={subTopicSelectedTags} companySelectedTags={companySelectedTags} setCompanySelectedTags={setCompanySelectedTags}  searchBarData={searchBarData}
setLevel={setLevel} level={level}
homeSelectedCompany={companylocationvalue.state}
/>
</div>
<div className='col-lg-9'>
<MainContainer subTopicSelectedTags={subTopicSelectedTags} companySelectedTags={companySelectedTags} setSearchBarData={setSearchBarData} setSetSubTopicSelectedTags={setSetSubTopicSelectedTags} setCompanySelectedTags={setCompanySelectedTags} level={level} setLevel={setLevel}/>
</div>
    </div>
    <FooterTwo />
 </>
  )
}

export default CodingHome