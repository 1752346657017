import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.js';
import { PostApi } from '../../../services/CommonService2.js';
// import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';

function StateCategory(props) {
    const stateId = useLocation();



    const [universityData, setUniversityData] = useState([]);
    const [branches, setBranches] = useState([]);
    const [year, setYear] = useState([]);
    const [semister, setSemister] = useState([]);
    // const [postAllData,setPostAllData]=useState({universityDataid:"", branchid:"",academictypeid:"",semisterid:""});
    const [selected, setSelected] = useState(undefined);
    const [selected1, setSelected1] = useState(1);
    const [selected2, setSelected2] = useState(1);
    const [universities, setUniversity] = useState(undefined);

    //my var
    const [typeslist, settypesList] = useState([]);
    const [college, setCollege] = useState('')
    const [branchId, setBranchId] = useState('')
    const [userBranchId,setUserBranchId]=useState(null)
    const [showSelectBranch,setShowSelectBranch]=useState(false)

    useEffect(() => {
        getUserBranchInfo()
        setTimeout(()=>{
            setShowSelectBranch(true)
        },3000)
        sectionFun();
        submition();
        
    }, [])

    const sectionFun = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const sessiondetails = localStorage.getItem(`userdata${tntId}`);
        const userdata = JSON.parse(sessiondetails);
        console.log(userdata, "check user branch form here")
       
        // setCollege(userdata?.user?.subapplicationid )

        var reqparam;
        if (userdata.user.studentedutype) {

            reqparam = {
                pageno: '-1',
                query: { status: 'Active', edutype: userdata?.user?.studentedutype }

            }
        } else {

            reqparam = {
                pageno: '-1',
                query: { status: 'Active', edutype: "btech" }

            }
        }


        var reqparams1 = '';
        if (stateId.state && (userdata?.user?.subapplicationid == '' || userdata?.user?.subapplicationid == undefined)) {
            reqparams1 = {
                pageno: '-1',
                query: { status: "Active", stateid: stateId.state.data, edutype: userdata?.user?.studentedutype }
            }

        } else if (stateId.state && userdata?.user?.subapplicationid) {
            reqparams1 = {
                pageno: '-1',
                query: {
                    status: "Active", stateid: stateId.state.data, edutype: userdata?.user?.studentedutype,
                    area: {
                        $elemMatch: { "value": userdata?.user?.subapplicationid }
                    }
                }
            }

        }
        else {
            reqparams1 = {
                pageno: '-1',
                query: {
                    status: 'Active', edutype: userdata?.user?.studentedutype
                }
            }

        }


        const ResponseData = await PostApi(reqparam, 'BRANCHES');
        const ResponseData2 = await PostApi(reqparam, 'ACADEMICTYPE');
        const ResponseData3 = await PostApi(reqparam, 'SEMISTER');
        const ResponseData4 = await PostApi(reqparams1, 'UNIVERSITY');

        //    setSemister(ResponseData3.data);
        //    setBranches(ResponseData.data);
        //    setYear(ResponseData2.data);
        //    setUniversityData(ResponseData4.data);

        console.log("universityDataResponse", ResponseData4.data);

        let count = 0;
        const rowdat = [];
        await ResponseData4?.data.reduce(async (promise, res) => {
            await promise;
            const opts = {
                key: res.id,
                value: res.name
            }
            rowdat.push(opts);
            if (count == ResponseData4.data.length - 1) {

                setUniversityData(rowdat);
                //    console.log("rowdat",rowdat);
            }
            count++;
        }, Promise.resolve());

        let counter1 = 0;
        let counter2 = 0;
        let counter3 = 0;
        const rowdata1 = [{
            key: 1,
            value: 'Sem1'
        },
        {
            key: 2,
            value: 'Sem2'
        }
        ];
        const rowdata2 = [];
        const rowdata3 = [

            {
                key: 1,
                value: '1st Year',
                studentedutype: 'btech'
            },
            {
                key: 2,
                value: '2nd Year',
                studentedutype: 'btech'
            },
            {
                key: 3,
                value: '3rd Year',
                studentedutype: 'btech'
            },
            {
                key: 4,
                value: '4th Year',
                studentedutype: 'btech'
            },

        ];

        setSemister(rowdata1);

        setYear(rowdata3);


        await ResponseData.data.reduce(async (promise, res) => {
            await promise;
            const opts = {
                key: res.id,
                value: res.name
            }
            rowdata2.push(opts);
            if (counter2 == ResponseData?.data.length - 1) {

                setBranches(rowdata2);
                // console.log("rowdat2",rowdata2);

            }
            counter2++;
        }, Promise.resolve());
    }
    var semin = 1;
    var yearin = 1;
    var submition = (year, sem) => {
        if (sem > 0) {
            setSelected1(sem)
            setSelected2(year)
            semin = sem;
            yearin = year
        }
        props.setSearchData("")
        // console.log("selected1===========",selected1);
        // if (selected1 == undefined || selected2 == undefined || selected == undefined
        //     || selected1 == '' || selected2 == '' || selected == ''
        // ) {

        //     // toast.error("All the fields are mandatory", {
        //     //     position: "top-right",
        //     //     autoClose: 5000,
        //     // });
        //     toast.info('Please select all the fields to check courses', {
        //         position: "top-center",
        //         autoClose: 8000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "light",
        //     });
        //     props.setStateData([])

        // } else {

        const reqdata = {
            branchid: branchId,
            semisterid: String(semin),
            academictypeid: String(yearin),

        }
        console.log(reqdata,"check branch req data")
        props.setStateData(reqdata)


    }

    const handleUserBranchId = async (e) => {
        setUserBranchId(e.target.value)
    }
    
    const updateUserBranchId = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const sessiondetails = localStorage.getItem(`userdata${tntId}`);
        const userdata = JSON.parse(sessiondetails);
        try {
            const reqprams = {
                branchid:userBranchId,
                userid:userdata.id
            }

            const updateUserBranch = await PostApi(reqprams,"UPDATEBRANCH")
            getUserBranchInfo()
        } catch (error) {
            console.log(error)
        }
    }

    const getUserBranchInfo =async()=>{
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const sessiondetails = localStorage.getItem(`userdata${tntId}`);
        const userdata = JSON.parse(sessiondetails);
        const reqdata={
            userid:userdata.id
        }
       
        const userBranch = await PostApi(reqdata,'USERBRANCHINFO');
        console.log(userBranch,"userBranch userBranch userBranch userBranch userBranch userBranch userBranch ")
        if(userBranch.data?.branch){
            setBranchId(userBranch.data.branch)
            

        }else{
            setBranchId(null)
        }
    }
    return (


        <div style={{ width: "100vw" }}>
            {/* {console.log(branches,"check branches from here")} */}

            <div >
                <h3 className='h-auto text-center  mb-3' >Academic Subjects</h3>
                <p className='text-center text-warning mb-3 fs-3'> <b>{branches.filter((ele) => {
                    return ele.key == branchId
                })?.[0]?.value}</b></p>
                {branches.filter((ele) => {
                    return ele.key == branchId
                }).length == 0 && showSelectBranch&& <div className='d-flex justify-content-center h-auto'>
                        <Modal show={true} className='h-auto'>
                            <Modal.Body style={{ height: "auto" }}>
                                < p className='text-center mb-3 text-primary'><b>Please Pick Your Branch</b></p>
                                <select class="form-select h-auto mb-3" onChange={handleUserBranchId}>
                                    <option selected disabled>Select Branch</option>
                                    {branches.map((ele, ind) => {
                                        return <option key={ind} value={ele.key}>{ele.value}</option>
                                    })}

                                </select>
                                <div className='d-flex justify-content-end'>
                                    <button className='btn btn-success text-right' onClick={updateUserBranchId}>Submit</button>
                                </div>
                            </Modal.Body>



                        </Modal>
                    </div>}
                <div className='row mb-5'>
                    {/* <ul className="col-lg-2 category-item1 list-unstyled h-auto">
                        <li >
                            <select value={selected} onChange={(e) => { setSelected(e.target.value); submition() }} className="form-control" name='branchid'>
                                <option value="" >Select Branches</option>
                                {branches?.map((item) => {
                                    return (
                                        <option class="dropdown-item" key={item.key} value={item.key}>
                                            {item.value}
                                        </option>
                                    )
                                })}
                            </select>
                        </li>
                    </ul> */}
                    <ul className="col-lg-12 category-item1 list-unstyled">
                        {/* <li className="check-btn">
  
                            <select value={universities}  onChange={(e) => setUniversity(e.target.value)} className="form-control" name='universityDataid'>
                                <option value="" >Select universityData</option>
                                {universityData?.map((item) => {
                                    return (
                                        <option class="dropdown-item" key={item.key} value={item.key}>
                                                        {item.value}
                                                    </option>
                                    )
                                })}
                            </select>

                        </li><br></br> */}

                        {/* <li className="check-btn">
                        <select  value={selected1} onChange={(e) => setSelected1(e.target.value)} name='academictypeid'>
                            <option value="" >Select Year</option>
                           
                                {year?.map((item)=>{
                                    return(
                                // <option class="dropdown-item" value={item.id}>{item.name}</option>
                                <option class="dropdown-item" key={item.key} value={item.key}>
                                                        {item.value}
                                                    </option>
                                )
                            })}
                               </select> 
                        </li> */}


                        {/* <li className="check-btn">
                        <select value={selected2} onChange={(e) => setSelected2(e.target.value)} name='semisterid'>
                            <option value="" >Select Semister</option>
                            {console.log(semister,"semister")}
                                {semister?.map((item)=>{
                                    return(
                                // <option class="dropdown-item" value={item.id}>{item.name}</option>
                                <option class="dropdown-item" key={item.key} value={item.key}>
                                {item.value}
                            </option>
                                )
                            })}
                               </select> 
                        </li> */}
                        <div className='row p-0 m-0 d-flex justify-content-center'>

                            {
                                year.map((ele) => {
                                    return semister.map(element => {
                                        return (
                                            <button className='col-lg-2 px-3 py-2 btn btn-primary mx-5 mb-3' value={ele.key} onClick={() => submition(ele.key, element.key)}>
                                                <h6> {ele.value} {element.value}</h6>
                                            </button>
                                        )
                                    })

                                })
                            }



                            {/* <div className='d-flex flex-column justify-content-center align-items-center mx-3' style={{ height: "40vh" }}>
                                {
                                    semister.map((ele) => {
                                        return (<button className='px-5 py-2 btn btn-primary mt-3 w-100' value={ele.key} onClick={() => setSelected1(ele.key)}>
                                            <h6> {ele.value}</h6>
                                        </button>
                                        )
                                    })
                                }
                            </div> */}
                            {/* <div className='d-flex flex-column justify-content-center align-items-center mx-3'>
                                <button className='btn btn-success' onClick={submition}>Search</button>
                            </div> */}
                        </div>

                        {/* <li className=' search-btn' >
                            <button onClick={submition} className='btn btn-dark'>Search</button>
                        </li> */}
                    </ul>
                </div>



            </div>

        </div>

    )
}

export default StateCategory
