import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Register from '../pages/account/Register';
import { Styles } from '../pages/account/styles/account';
import { GetApi } from '../services/CommonService2';


function LoginModal(props) {
  //   const [show, setShow] = useState(false);

  //   const handleClose = () => setShow(false);
  //   const handleShow = () => setShow(true);

const [showotp,setShowOtp] =useState(false);
const [userName,setUserName]=useState('');
const [password,setPassword]=useState("")
const [otp,setOtp] =useState('');
const [postModal,setPostModal]=useState(false);
const history = useHistory();
const [modalShow,setModalShow]=useState(true)
const [isForgotPass,setIsForgotPass]=useState(false)
const [passwordVisible, setPasswordVisible] = useState(true)







  const saveToken = async (userdata) => {
    var tntId = JSON.parse(localStorage.getItem('tID'))
    localStorage.setItem(`userdata${tntId}`, JSON.stringify(userdata));
  }

  const onLogin = async (e) => {
    e.preventDefault()
    const respdata = {
      username: userName,
      tenantid: ''
    }
    const ResponseMessage = await GetApi(respdata, 'LOGIN');

    // console.log("ResponseMessage=", ResponseMessage);

    if (ResponseMessage.data.mobileno) {
      setShowOtp(true);

      toast.success('Otp  sent to your mobile number successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (ResponseMessage.data == 'USER_NOT_FOUND') {
      toast.info('Please kindly register or Login with registered mobile number', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    else if (ResponseMessage.data == 'INACTIVE') {

      toast.error('Your account is inactive ,Please contact admin', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    }
    else if (ResponseMessage.data == 'ALREADY_LOGGEDIN') {

      toast.error('You have already logged in with this mobile number , Please kindly logout or contact support team', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    }

    // else{
    //   toast.error('Incorrect Mobile number', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //     });
    // }
  }


  const verifyOtp = async (e) => {
    e.preventDefault()
    const respdata = {
      username: userName,
      otp: otp
    }
    const ResponseMessage = await GetApi(respdata, 'VERIFY');

    // console.log("Verify OTP data:",ResponseMessage);

    if (ResponseMessage.data == 'OTP_EXPIRED') {

      toast.error('OTP has expired ', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    } else if (ResponseMessage.data == 'INVALID_OTP') {

      toast.error('Invalid otp  ', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    } else {
      await saveToken(ResponseMessage)
      // console.log("OTP worked!!@");
      props.onClose()
      props.login()
      // history.push(`${ "/"}`)
      console.log("loginn working!!!!");
      if(isForgotPass){
    
      history.push('/changepassword', { isForgetPass: true });

      toast.success('Please change the temporary password', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

      }else{
        window.location.href = '/'
      }
    }
  }

  function formDetails(event) {

    if (event.target.name === "userName") {
      setUserName(event.target.value)
    }

    if (event.target.name === "password") {
      setPassword(event.target.value)
    }

    // if (event.target.name==="otp"){
    //   setOtp(event.target.value) 
    // }


  }

  const openModal = () => {
    // setPostModal(true)
    setModalShow(true)
    // onClose()


  }
  const closemodal = () => {
    setPostModal(false)
    setModalShow(true)

  }

  const showToggle = () => {

    // console.log("working");

    setModalShow(false)
    setPostModal(true)


  }

  const passLogin = async (e) => {
    e.preventDefault()

    if (userName == '') {
      toast.error('Enter Mobile Number', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    }
    else if (password == '') {
      toast.error('Enter Password', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    }
    else {
      const respdata = {
        username: userName,
        password: password
      }
      
      const ResponseMessage = await GetApi(respdata, 'PASSLOGIN');
      if (ResponseMessage.data == 'USER_NOT_FOUND') {
        toast.error('User not found ', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (ResponseMessage.data == 'INVALIDPASSWORD') {
        toast.error('Invalid password ', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      else if (ResponseMessage.data == 'INACTIVE') {
        const result = window.confirm('Your account is inactive, please contact admin');
      }
      else if (ResponseMessage.data == 'ALREADY_LOGGEDIN') {
        const result = window.confirm('You have already logged in with this mobile number , Please kindly logout or contact support team');
      }
      else {
        await saveToken(ResponseMessage)
        props.onClose()
        props.login()
        // history.push(`${ "/"}`)
        console.log("loginn working!!!!");
       
        if(isForgotPass){
    
          history.push('/changepassword', { isForgetPass: true });
    
          toast.success('Please change the temporary password', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    
          }else{
            window.location.href = '/'
          }
      }
       // else{
       // const result = window.confirm('Temporary password has been sent to your registered email id , Please login and change your password');
       // setIsForgotPass(true)
       // }

    }
}

  const forgotpassword = async () => {
    if (userName == '') {
      toast.error('Enter Mobile Number', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const respdata = {
        username: userName,
        tenantid: ''
      }
      const ResponseMessage = await GetApi(respdata, 'FORGOTPASSWORD');
      if (ResponseMessage.data == 'USER_NON_EXISTS') {
        toast.error('Invalid User', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const result = window.confirm('Temporary password has been sent to your registered email id , Please login and change your password');
        setIsForgotPass(true)
      }
    }
  }

  const ViewPassword = async () => {
    setPasswordVisible(!passwordVisible)

  }
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch static backdrop modal
      </Button> */}


      <Modal
        show={modalShow}
        // onHide={modalOpen}
        // backdrop="static"
        keyboard={false}
        style={{zIndex:99999}}
      >
        {/* closeButton */}
        <Modal.Header >
          <Modal.Title>Login Form</Modal.Title>
          <button onClick={props.onClose} style={{ marginLeft: "auto", border: "none" }}>X</button>
        </Modal.Header>
        <Modal.Body>
          <Styles>
            <section className="login-area">
              <Container>
                <Row>
                  <Col md="12">
                    <div className="login-box">
                      <div className="login-title text-center">
                        <h3>Log In</h3>
                      </div>
                      <form id="form_login" className="form">
                        <p className="form-control">
                          <label htmlFor="login_user">Login</label>
                          <input type="type" placeholder="Mobile Number/Email" id="login_user" name='userName' onChange={formDetails} value={userName} />
                          <span className="login_input-msg"></span>
                        </p>
                        <p className="form-control">
                          <label htmlFor="login_user">Password</label>
                          <p>
                            <input type={passwordVisible ? "password" : "text"} placeholder="Password" id="login_password" name='password' onChange={formDetails} className='password1' value={password} />
                            {passwordVisible ? <button1 type="submit" onClick={ViewPassword}><i className="fa-regular fa-eye passwordeye"></i></button1> : <button1 type="submit" onClick={ViewPassword}><i className="fa-regular fa-eye-slash passwordeye"></i></button1>}

                          </p>


                        </p>
                        <button onClick={passLogin}>Log In</button>
                        {/* {showotp &&  <p className="form-control">
                                            <label htmlFor="login_password">OTP</label>
                                            <input type="text" placeholder="Enter 6 digit Otp" id="login_password" name='otp' onChange={formDetails} value={otp}/>
                                            <span className="login_input-msg"></span>
                                        </p> } */}
                        {/* {showotp ?<button onClick={verifyOtp}>Verify OTP</button>:<button onClick={onLogin}>Log In</button> }   */}

                        {/* {showotp && <label onClick={onLogin} style={{ textDecorationLine: 'underline' }}>Resend OTP</label>} */}


                        {/* <div className="save-forget-password d-flex justify-content-between">
                                            <div className="save-passowrd">
                                                <label htmlFor="save_password"><input type="checkbox" id="save_password" className="check-box" />Save Password</label>
                                            </div>
                                            <div className="forget-password">
                                                <Link to={process.env.PUBLIC_URL + "/"}>Forget Password?</Link>
                                            </div>
                                        </div> */}
                        <div className="not_account-btn text-center">
                          Haven't Any Account Yet?  <label onClick={showToggle} style={{ textDecorationLine: 'underline' }}>Signup</label>
                        </div>

                        <div className="text-center fw-bold fs-6 text-primary">
                          <label onClick={forgotpassword} class="forgot-password">Forgot Password</label>
                        </div>

                        {/* <div className="social-login text-center">
                                            <p>Login With Social</p>
                                            <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-google"></i> Google</a></li>
                                                <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i> Facebook</a></li>
                                                <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i> Twitter</a></li>
                                            </ul>
                                        </div> */}
                      </form>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </Styles>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
      {/* <ToastContainer /> */}


      <Modal size="l" show={postModal} >
        <div className="" role="document">
          <div className="">
            <form >
              <div className="modal-header">

                <button type="button" className="btn-close" onClick={closemodal} data-dismiss="modal"></button>
              </div>
              <div className="modal-body">
                <Register closemodal={closemodal} loginmodal={openModal} />
              </div>
            </form>

          </div>
        </div>




      </Modal>
    </>
  );
}

export default LoginModal;