import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import LoginModal from '../../components/LoginModal.js';
import { GetApi, PostApi } from '../../services/CommonService2.js';
import { Styles } from './styles/account.js';



function Register(props) {
  var stateinfo = useRef();
  const [fbaddidss, setFbaddidss] = useState("")
  const [tenantId, setTenantId] = useState("");
  const [branches,setBranches]=useState([])
  useEffect(() => {
    loadDataFirst()
    var fb = localStorage.getItem('fbAddId')
    if (fb != "") {
      setFbaddidss(fb)
    } else {
      setFbaddidss("VisualpathEdTech")
    }
    // getstate();


  }, [])

  const fields = {
    name: '',
    email: '',
    mobileno: '',
    state: '',
    constituency: '',
    password: '',
    districtid: '',
    fbaddid: '',
    studentedutype: '',
    confirmpassword: '',
    branch:''
  }
  const errors = {
    name: '',
    email: '',
    mobileno: '',
    state: '',
    constituency: '',
    password: '',
    districtid: '',
    studentedutype: '',
    branch:''
  }

  const [errorFields, setErrorFields] = useState(errors);
  const [formfields, setFormFields] = useState(fields);
  const [isCheck, setIschecked] = useState(false);
  const [showotp, setShowotp] = useState(false);
  const [stateidd, setStateId] = useState('')
  // const [toggleLogin,setToggleLogin]=useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  const [tenantdata, setTenantData] = useState({});
  const [placeholder, setPlaceholder] = useState("Select Constituency");
  const [statedata, setStateData] = useState([]);
  const [states, setStates] = useState([])
  const [constituencies, setConstituency] = useState([])
  const [collegedata, setCollegeData] = useState([])
  const [otp, setOtp] = useState(null);
  const [selected, setSelected] = useState('');
  const [districtdata, setDistrictData] = useState([]);
  // const [districtid,setDistrictid] =useState(null);
  const [passworderr, setPassworderr] = useState(false);
  const [passwordmsg, setPasswordMsg] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(true)


  const passwordSchema = Yup.string()
    .min(8, 'Password must have (A-Z,a-z,0-9,@$!%*?&) and 8-15 characters.(Too short)')
    .max(15, 'Password must have (A-Z,a-z,0-9,@$!%*?&) and 8-15 characters.(Too long)')
    .matches(/[a-z]/, 'Password must have (A-Z,a-z,0-9,@$!%*?&) and 8-15 characters.(Small letter missing)')
    .matches(/[A-Z]/, 'Password must have (A-Z,a-z,0-9,@$!%*?&) and 8-15 characters.(Capital letter missing)')
    .matches(/\d/, 'Password must have (A-Z,a-z,0-9,@$!%*?&) and 8-15 characters.(Number missing)')
    .matches(/[@$!%*?&]/, 'Password must have (A-Z,a-z,0-9,@$!%*?&) and 8-15 characters.(Special character missing)');






  const { name, email, mobileno, state, constituency, password, districtid, studentedutype, confirmpassword,branch } = formfields;
  const validateForm = async () => {
    let fields = formfields;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "*Please enter  name";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter email";
    }

    if (!fields["mobileno"]) {
      formIsValid = false;
      errors["mobileno"] = "*Please enter mobileno.";
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter password.";
    }
    if (!fields["studentedutype"]) {
      if (tenantId != "ffe21ec9") {
        formIsValid = false;
        errors["studentedutype"] = "*Please select studentedutype.";
      }
    }
    if (!fields["branch"]) {
      if (tenantId != "ffe21ec9") {
        formIsValid = false;
        errors["branch"] = "*Please select branch.";
      }
    }
    if (tenantdata.capturestate == "Yes") {
      if (!fields["state"]) {
        formIsValid = false;
        errors["state"] = "*Please select state.";
      }
    }
    if (tenantdata.capturecollege == "Yes") {
      if (!fields["constituency"]) {
        formIsValid = false;
        errors["constituency"] = "*Please select college.";
      }

    }

    if (tenantdata.capturearea == "Yes") {
      if (!fields["constituency"]) {
        formIsValid = false;
        errors["constituency"] = "*Please select constituency.";
      }
    }
    if (tenantdata.showdistrict == "Yes") {
      if (!fields["districtid"]) {
        formIsValid = false;
        errors["districtid"] = "*Please select region.";
      }
    }

    setErrorFields(errors)
    return formIsValid;

  }

  var edutypeName = "btech";

  const handleChange =async (e) => {

    e.preventDefault();

    const { name, value } = e.target;
    edutypeName = value
    setFormFields((prevState) => ({
      ...prevState,
      [name]: value,

    }));
    if (e.target.name == "state") {
      // console.log("value nnn", value);
      setStateId(value)
      getCollegeByState(value)
    }

    if (e.target.name == "constituency") {

      setSelected(value)
      // console.log("collge id", value);

    }
    if (e.target.name == "districtid") {

      getconstituencybydistricts(value)

    }
  
   var reqparam = {
      pageno: '-1',
      query: { status: 'Active',edutype:edutypeName}

  }

  const ResponseData = await PostApi(reqparam, 'BRANCHES');
  console.log(ResponseData)
  setBranches(ResponseData.data)
  
  }

  const handleBranchChange =(e)=>{
    const { name, value } = e.target;
   
    setFormFields((prevState) => ({
      ...prevState,
      [name]: value,

    }));
  }


  const history = useHistory();

  const loadDataFirst = async () => {
    var tntId = JSON.parse(localStorage.getItem('tID'))
    setTenantId(tntId)
    const value = localStorage.getItem(`tenantdata${tntId}`);
    if (value !== null) {
      setTenantData(JSON.parse(value));
      let tenanat = JSON.parse(value);
      // console.log("tenantdata", tenanat);

      if (tenanat.capturearea == 'Yes' && tenantdata?.showdistrict == 'No') {
        // console.log("inside condition");
        setPlaceholder("Select Constituency");
        getConsittuencies();
      }
      if (tenanat.capturearea == 'Yes' && tenantdata?.showdistrict == 'Yes') {
        setPlaceholder("Select Constituency");

      }
      if (tenanat.capturecollege == 'Yes') {
        setPlaceholder("Select College");

      }
      if (tenanat.capturestate == 'Yes') {
        // setPlaceholder("Select College");
        getstate();
      }
    }


  }

  const getstate = async () => {
    const reqparam = {
      pageno: "-1",
      query: { status: 'Active', tenant: tenantdata.tenantid },

    }
    const ResponseMessage = await PostApi(reqparam, 'STATES');
    // console.log("ResponseMessage", ResponseMessage.data);
    setStates(ResponseMessage.data)

    let counter = 0;
    const rowdata = [];
    await ResponseMessage.data.reduce(async (promise, res) => {
      await promise;
      const opts = {
        key: res.id,
        value: res.name
      }
      rowdata.push(opts);
      if (counter == ResponseMessage.data.length - 1) {

        setStateData(rowdata);
      }
      counter++;
    }, Promise.resolve());

  }
  const getConsittuencies = async () => {
    const reqparam = {
      pageno: "-1",
      tenantid: tenantdata.tenantid,
      query: { status: 'Active' }
    }


    const ResponseMessage = await GetApi(reqparam, 'CONSTITUENCY');
    // console.log("constituency data", ResponseMessage.data);
    setConstituency(ResponseMessage.data)


    let counter = 0;
    const rowdata = [];
    await ResponseMessage.data.reduce(async (promise, res) => {
      await promise;
      const opts = {
        key: res.id,
        value: res.name
      }
      rowdata.push(opts);
      if (counter == ResponseMessage.data.length - 1) {

        // setData(rowdata);
      }
      counter++;
    }, Promise.resolve());



  }

  const getCollegeByState = async (val) => {


    if (tenantdata.showdistrict == 'Yes') {
      const reqparam = {
        pageno: "-1",
        query: { status: 'Active', stateid: val }
      }
      const ResponseMessage = await GetApi(reqparam, 'DISTRICTS');
      let counter = 0;
      const rowdata = [];
      if (ResponseMessage.data.length > 0) {
        await ResponseMessage.data.reduce(async (promise, res) => {
          await promise;
          const opts = {
            key: res.id,
            value: res.name
          }
          rowdata.push(opts);
          if (counter == ResponseMessage.data.length - 1) {

            setDistrictData(rowdata);
          }
          counter++;
        }, Promise.resolve());
      }
    }

    else {
      const reqparam = {
        pageno: "-1",
        tenantid: tenantdata.tenantid,
        query: { status: 'Active', stateid: val }
      }

      const ResponseMessage = await GetApi(reqparam, 'CONSTITUENCY');
      // console.log("collegeeee data", ResponseMessage.data);
      setConstituency(ResponseMessage.data)
      let counter = 0;
      const rowdata = [];
      if (ResponseMessage.data.length > 0) {
        await ResponseMessage.data.reduce(async (promise, res) => {
          await promise;
          const opts = {
            key: res.id,
            value: res.name
          }
          rowdata.push(opts);
          if (counter == ResponseMessage.data.length - 1) {

            // setData(rowdata);
          }
          counter++;
        }, Promise.resolve());
      }
    }



  }


  const getconstituencybydistricts = async (districtval) => {

    setConstituency([]);
    const reqparam = {
      pageno: "-1",
      query: { status: 'Active', districtid: districtval }
    }
    const ResponseMessage = await GetApi(reqparam, 'CONSTITUENCY');

    let counter = 0;
    const rowdata = [];
    if (ResponseMessage?.data.length > 0) {
      await ResponseMessage.data.reduce(async (promise, res) => {
        await promise;
        const opts = {
          id: res.id,
          name: res.name
        }
        rowdata.push(opts);
        if (counter == ResponseMessage?.data.length - 1) {

          setConstituency(rowdata);
          console.log("row data of constituency", rowdata);
        }
        counter++;
      }, Promise.resolve());
    }

  }

  const sendsms = async (e) => {
    console.log(formfields, "form fileds data")
    e.preventDefault();

    const isValid = await validateForm();
    const password1 = await checkPassword()

    if (isValid && password1) {
      if (password != confirmpassword) {
        toast.error('Password and Confirm password should match', {
          position: "top-center",
          autoClose: 5000
        })

      }
      else {

        const reqdata = {
          username: mobileno,
          query: { tenant: tenantdata.tenantid },
          mobileno: mobileno
        }

        // console.log("reqsms",reqdata);

        const verifyotpResp = await GetApi(reqdata, 'SENDVERIFYMOBILE');

        console.log("otpp", verifyotpResp.data);

        if (verifyotpResp.data.id) {

          toast.success('OTP sent successfully to your registered mobile', {
            position: "top-center",
            autoClose: 5000
          })
          setShowotp(true);


        } else {
          if (verifyotpResp.data == 'ALREADY_EXISTS') {

            toast.info("mobile number already exists", {
              position: "top-center",
              autoClose: 5000
            })


          } else {
            toast.info('Failed to send otp to the mobile', {
              position: "top-center",
              autoClose: 5000
            })

          }
        }

      }
    }
  }

  const [ismobileverified, setIsMobileVerified] = useState(false);

  const verifysms = async (e) => {
    e.preventDefault();
    const reqdata = {
      query: { tenant: tenantdata.tenantid },
      username: mobileno,
      otp: otp,

    }

    const verifyotpResp = await GetApi(reqdata, 'VERIFYMOBILE');
    console.log("verifyotpResp", verifyotpResp.data);

    if (verifyotpResp.data.id) {
      setIsMobileVerified(true);
      register("abc");

    } else {
      if (verifyotpResp.data == 'INVALID_OTP') {
        toast.info("invalid OTP", {
          position: "top-center",
          autoClose: 5000

        })


      } else if (verifyotpResp.data == 'OTP_EXPIRED') {
        toast.info("OTP expired", {
          position: "top-center",
          autoClose: 5000

        })


      }
      else {

        toast.info("Failed to verify OTP", {
          position: "top-center",
          autoClose: 5000

        })

      }
    }

  }

  const register = async (e) => {

    if (e != "abc") {
      e.preventDefault();
    }

    if (password == '') {
      toast.error('please enter password', {
        position: "top-center",
        autoClose: 5000

      })
    }
    else if (password.length < 6) {
      toast.info('password should be minimum of 6 characters', {
        position: "top-center",
        autoClose: 5000

      })
    }

    else {

      const reqdata = {
        username: mobileno,
        email: email,
        name: name,
        tenantid: tenantdata.tenantid,
        tenant: tenantdata,
        roles: ["USER"],
        subapplicationid: selected,
        stateid: stateidd,
        mobileno: mobileno,
        password: password,
        districtid: districtid,
        fbaddid: fbaddidss,
        studentedutype: studentedutype,
        branch:branch
      }



      const reqData = await GetApi(reqdata, 'SIGNUP');
      console.log("reqData after response", reqData);



      if (reqData.data == 'SUCCESS') {
        toast.success("user was registered succesfully ", {
          position: "top-center",
          autoClose: 5000

        })
        //  openModal()

        props.loginmodal()
        props.closemodal()

      }
      else {
        toast.error("mobile number already exists", {
          position: "top-center",
          autoClose: 5000

        })

      }
    }



  }

  const checkPassword = async () => {
    let passwordvalid = false
    try {
      await passwordSchema.validate(password)
      setPasswordMsg('')
      passwordvalid = true

    }
    catch (error) {
      console.log("error", error.message)
      setPassworderr(true)
      setPasswordMsg(error.message)
      passwordvalid = false
    }
    return passwordvalid
  }

  const openModal = () => {
    setModalOpen((prevState) => !prevState);

  };
  const ViewPassword = async () => {
    setPasswordVisible(!passwordVisible)

  }


  const customScore = (password) => {
    let score = 0;

    if (password.length >= 8) score += 1;
    if (password.length >= 8 && password.match(/[a-z]/)) score += 1;
    if (password.match(/[A-Z]/)) score += 1;
    if (password.match(/\d/)) score += 1;
    if (password.match(/[@$!%*?&]/)) score += 1;
    console.log("The score is mate", score);
    // return score; // Returns a score between 0 and 5
  };

  const customScoreWords = ["weak", "okay", "good", "strong", "very strong"];
  const password2 = "MyPassword123!"
  // const score = customScore(password);
  return (
    <Styles>
      {console.log(branches,"check branches")}
      <div className="main-wrapper registration-page">
        <Container className="registration-area">
          <Row>
            <Col md="12">
              <div className="registration-box">
                <div className="registration-title text-center">
                  <h3>Registration</h3>
                </div>
                <form id="form_registration" className="form">
                  <p className="form-control">
                    {/* <label htmlFor="registration_fname"> Name</label> */}
                    <input type="text" placeholder="Name" id="registration_fname" name="name" value={name} onChange={handleChange} />
                    <p>{errorFields.name}</p>

                  </p>

                  <p className="form-control">
                    {/* <label htmlFor="registration_email">Email Address</label> */}

                    <input type="email" placeholder="Email address" id="registration_email" name="email" value={email} onChange={handleChange} />
                    <p>{errorFields.email}</p>
                  </p>

                  <p className="form-control">
                    <input type="text" placeholder="mobile number" name="mobileno" value={mobileno} onChange={handleChange} />
                    <p>{errorFields.mobileno}</p>


                  </p>

                  <p className="form-control">
                    <input type={passwordVisible ? "password" : "text"} placeholder="password" name="password" value={password} onChange={handleChange} className='password1' />
                    {passwordVisible ? <button1 type="submit" onClick={ViewPassword}><i className="fa-regular fa-eye passwordeyeregister"></i></button1> : <button1 type="submit" onClick={ViewPassword}><i className="fa-regular fa-eye-slash passwordeyeregister"></i></button1>}


                    {/* {password &&
                      <PasswordStrengthBar
                        password={password}
                        // // minLength={8}
                        // scoreWords={customScoreWords}
                        // shortScoreWord="too short"
                        // score={score}       
                        // score={3}                

                      />
                      } */}

                  </p>
                  {passworderr ? <p style={{ color: "red" }}>{passwordmsg}</p> : <p>{errorFields.password}</p>}
                  <p className="form-control">
                    <input type="password" placeholder="confirm password" name="confirmpassword" value={confirmpassword} onChange={handleChange} />
                  </p>
                  {
                    tenantId != "ffe21ec9" ? <p className="form-control">
                      <select className="form-control"
                        name="studentedutype" value={studentedutype}
                        onChange={handleChange}
                        ref={stateinfo}


                      >
                        <option value='' style={{ padding: "10px" }}>Select Education</option>
                        <option value="btech">Btech</option>
                        <option value="degree">Degree</option>
                        <option value="pg">PG</option>

                      </select>
                      <p>{errorFields.studentedutype}</p>


                    </p> : ""
                  }

{
                    tenantId != "ffe21ec9" ? <p className="form-control">
                      <select className="form-control"
                        name="branch" value={branch}
                        onChange={handleBranchChange}
                        ref={stateinfo}


                      >
                        <option value='' style={{ padding: "10px" }}>Select Branch</option>
                        {branches.length==0 && <option value="">---- No Branch Selected ----</option>}
                        {branches.map((ele,ind)=>{
                          return (<option value={ele.name} key={ind}>{ele.name}</option>)
                        })}
                        

                      </select>
                      {/* <p>{errorFields.studentedutype}</p> */}


                    </p> : ""
                  }


                  {/* {tenantdata.capturestate == 'Yes' && <p className="form-control">
                    <select className="form-control"
                      name="state" value={state}
                      onChange={handleChange}
                      ref={stateinfo}


                    >
                      <option value=''>Select State</option>
                      {states.map(item => (
                        <option
                          key={item.id}
                          value={item.id}


                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <p>{errorFields.state}</p>
                  </p>



                  } */}


                  {tenantdata.showdistrict == 'Yes' && <p className="form-control">
                    <select className="form-control"
                      name="districtid" value={districtid}
                      onChange={handleChange}
                      ref={stateinfo}


                    >
                      <option value='' style={{ padding: "10px" }}>Select Region</option>
                      {districtdata.map(item => (
                        <option
                          key={item.key}
                          value={item.key}


                        >
                          {item.value}
                        </option>
                      ))}
                    </select>
                    <p>{errorFields.districtid}</p>
                  </p>



                  }




                  {(tenantdata.capturearea == 'Yes' || tenantdata.capturecollege == 'Yes') &&
                    <p className="form-control">
                      <select className="form-control"
                        name="constituency" value={constituency}
                        onChange={handleChange}


                      >
                        <option value=''>{placeholder}</option>
                        {constituencies.map(item => (
                          <option
                            key={item.id}
                            value={item.id}


                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <p>{errorFields.constituency}</p>
                    </p>}







                  {showotp &&

                    <p className="form-control">

                      <input type="text" placeholder="enter 6-digit OTP" pattern="[0-9]{6}" maxLength='6' onChange={(e) => setOtp(e.target.value)} value={otp} />
                    </p>
                    // <input type="text" pattern="[0-9]{6}" maxlength="6" placeholder="Enter 6-digit OTP" onChange={(value) => console.log("value",value)}/>
                  }



                  <label >
                    <input type="checkbox" id="agree" name="agree" onClick={() => setIschecked({ isCheck: !isCheck })} style={{ marginRight: "4px" }} />
                    I agree to the terms and conditions
                  </label>

                  {!showotp && <button onClick={sendsms}>Signup</button>}


                  {showotp && !ismobileverified && <button onClick={verifysms}>Verify OTP </button>}

                  {showotp && ismobileverified && <button onClick={register}>Continue </button>}

                  {showotp && !ismobileverified && <label onClick={sendsms} style={{ textDecorationLine: 'underline' }}>Resend OTP</label>}

                </form>
                {/* <div className="have_account-btn text-center">
                                        <p>Already have an account? <button onClick={()=>setModalOpen(true)}>Login Here</button></p>
                                    </div> */}
                {modalOpen && <LoginModal onClose={openModal}></LoginModal>}
              </div>
            </Col>
          </Row>
        </Container>
        {/* <ToastContainer /> */}






      </div>


    </Styles>
  )
}

export default Register