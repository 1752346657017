import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import { PostApi } from '../services/CommonService2';
import DOMPurify from 'dompurify';
function Editorial({problemContent}) {
    const [editorialData,setEditorialData]=useState({ description:"", youtubeurl:''})
    useEffect(()=>{
        getEditorialData()
    },[])
    const getEditorialData= async()=>{
      
const reqparams={
    pageno:"1",
    query: {problemid: problemContent.id}
    
}
const editorialOutput=await PostApi(reqparams,"EDITORIAL")
if(editorialOutput.status==200){
    setEditorialData(editorialOutput.data.docs[0])
}

    }
    const sanitizedData = (data) => {
        const sanitizedHtml=DOMPurify.sanitize(data)
        return {__html: sanitizedHtml}
      }
    return (
        <div >
            <h3 className='mt-1 mb-2'>Editorial</h3>
           <div className='p-4 border border-2'>
           <ReactPlayer url={editorialData?.youtubeurl} controls={true} className="img-fluid" width="100%"
            />
           </div>
            <div>
<p className='mt-4  fs-4 fw-bold'>Examples:</p>

<div className='p-3 my-3 border border-2 rounded' style={{ backgroundColor: "#e5e4e2" }} dangerouslySetInnerHTML={sanitizedData(editorialData?.description)}>
                        
                    </div>


            </div>
        </div>
    )
}

export default Editorial