import React, { useEffect,useState } from 'react'
import { FaGraduationCap } from "react-icons/fa";
import { PostApi,GetApi } from '../services/CommonService2';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'react-router-dom';
import LoginModal from '../components/LoginModal';
import './home3.css'
function HomePrograms3() {
  const [programs,setPrograms] =useState([])
  const [modalUp, setModalUp] = useState(false)
  const history = useHistory()
  const [show, setShow] = useState(false);
  const [item, setItem] = useState(null);
  const [item2, setItem2] = useState(null);
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [itemModal, setItemModal] = useState(false);
  const [redirect, setRedirect] = useState(false)
  const [CouponcodeNo, setCouponcodeNo] = useState(0)
  const [enrollFree, setEnrollFree] = useState(false)
  const [userData, setUserData] = useState(null)
  const [confirmation, setConformation] = useState(false)
  const [finalAmount, setFinalAmount] = useState(0)
  const [purchased, setPurchased] = useState(false)
  const [purchaseSUccessFull, setPurchaseSUccessFull] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [tenantData, setTenantData] = useState("")
  const [crt, setCrt] = useState(false)
  const [itemNew, setitemNew] = useState("")
const [isPolitical,setIsPolitical]=useState(false)
const [tcsEnroll,setTcsEnroll] = useState(false)
  useEffect(()=>{
    getDevopsPrograms()
  },[])
  const getDevopsPrograms =async()=>{
    const reqparam = {
      pageno: 1,
      // page:currentPage,
      query: { status: 'Active',category:'6629ed7e381d71a9457684bb'},
      usertype: 'APP',
      tenantid: '',
      optimize: true,
      userid: ""
  }
  const action = "ITPROGRAMS"
  const ResponseData = await PostApi(reqparam, action);
  console.log(ResponseData, "ResponseData initial courses") 
  setPrograms(ResponseData.data)

}
const handlePurchase = async () => {
  try {
    var tntId= await JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    if (sessiondetails != null) {
      const userdata = JSON.parse(sessiondetails);
      const reqparams1 = {
        pageno: 1,
        query: { userid: userdata.id, programid: item.id },
        // query: { userid: '6574158e4c53154af2cc274a'},
        ptype: 'PROGRAMPURCHASES'
      }

      let response = await PostApi(reqparams1, 'DASHOARDLABEL');
      // console.log(response, "response")
      if (!response?.data?.docs.length > 0) {
        setPurchased(false)
        setItemModal(true)
        setItem2(item)
        setShowStripeForm(true);
        setShow(false)
      } else {
        handleClose()
        setPurchased(true)
      }
    }
  } catch (error) {
    console.log(error)
  }

};

const handleClose = () => setShow(false);

const hashChangeEvent2 = async () => {

  try {
    var tntId= await JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    if (sessiondetails != null) {
      const userdata = JSON.parse(sessiondetails);
      setUserData(userdata)
      // console.log(userdata, "userdata")
      // add coupone here

      const reqParams = { name: userdata.username, amount: Number(item.sale_price), userid: userdata.id, courseid: item.id, couponcode: CouponcodeNo };

      const reqdataCoupon = await GetApi(reqParams, "STRIPEKEY");
      // console.log(reqdataCoupon, "reqdataCoupon")

      if (reqdataCoupon.message === 'SUCCESS') {

        const reqdata = {
          amount: Number(reqdataCoupon.amount),
          userid: userdata.id
        }
        const orderResp = await PostApi(reqdata, 'RAZORPAYORDERID');
        // console.log(orderResp, "orderResp")
        const options = {
          name: 'visualpath',
          image: 'https://asseshub.com/resume1/assets/images/adaptive-icon.png',
          description: 'Program purchase',
          order_id: orderResp.id,
          key: "rzp_live_VMuQP30hpyTin3",
          prefill: {
            email: userdata.email,
            contact: userdata.username,
            // name: userdata.userdata,
          },
          theme: { color: '#a29bfe' },
          handler: async (transaction) => {
            const resdata = {
              userid: userdata.id,
              transaction: transaction,
              orderID: orderResp.id,
              programid: item.id
            }
            const respSignatureData = await PostApi(resdata, 'VERIFYPAYMENTS');
            // console.log(respSignatureData.message, "respSignatureData");
            setConformation(false)
            setPurchaseSUccessFull(true)

            setTimeout(() => {
              setPurchaseSUccessFull(false)
            }, 10000)
          },
        };
        const razor = new window.Razorpay(options);
        // console.log(razor, "response razor")
        razor.open()
          .catch((error) => {
            console.log(error);
          });
      } else if (reqdataCoupon.message === 'INVALIDCOUPONCODE') {
        toast.info('Invalid coupon code or you have already applied it , Please go back and retry again', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCouponcodeNo(0)

      } else if (reqdataCoupon.message === 'COUPONAMOUNTMORE') {
        toast.info('coupon amount value is more than the course amount please choose a different program', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCouponcodeNo(0)
      } else if (reqdataCoupon.message === 'FREECOURSE') {
        setEnrollFree(true)
      }



    }
  } catch (error) {
    console.log(error)
  }
}






const publishableKey = 'pk_test_51ODj74SJbFT27gwoONAnMJ0endmdF071eX4FJ248gS37R5DAlcqrJ3YLiPxg053askOwRi7Pc4cl74mpyjXanfdZ00z0PVoKGR';

useEffect(() => {
  setStripePromise(loadStripe(publishableKey))
  const getTanant = async () => {
    const reqparam1 = {
      pageno: '-1',
      query: {}
    }

    const tenantresp = await PostApi(reqparam1, 'TENANT');
   
    setTenantData(tenantresp?.data[0])

  }
  getTanant()
}, [])



// const [currentPage, setCurrentPage] = useState(1);
// const recordsPerPage = 10;
// const lastIndex = props.currentPage * recordsPerPage;
// const firstIndex = lastIndex - recordsPerPage;
// const npage = Math.ceil(props.totalRecords?props.totalRecords/ recordsPerPage:0 / recordsPerPage)
// const records = props.courseList?.slice(firstIndex, lastIndex)
// const numbers = [...Array(npage + 1).keys()].slice(1)





// const prePage = () => {
//   window.scroll(0,0)
//   if (props.currentPage !== 1) {
//     props.setCurrentPage(props.currentPage - 1)
//   }


// }
// const nextPage = () => {
//   window.scroll(0,0)
//   if (props.currentPage !== npage) {
//     props.setCurrentPage(props.currentPage + 1)
//   }

// }
// const changeCPage = (id) => {
//   window.scroll(0,0)
//   props.setCurrentPage(id)

// }

const loginValidate = async (data) => {
  setitemNew(data)
  
  // data.preventDefault()
  var tntId= await JSON.parse(localStorage.getItem('tID'))

  const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
  if (userdata == null) {
    setModalUp(true)
    // data.preventDefault()
    toast.info('login to check course details', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else if (userdata != '' || userdata != null) {
    // if(data.on_sale="N" ){
    //   setCrt(true)
    // }

 
  

    const reqparams1 = {
      pageno: 1,
      query: { userid: userdata.id, programid: data.id },
      // query: { userid: '6574158e4c53154af2cc274a'},
      ptype: 'PROGRAMPURCHASES'
    }
    setItem(data)
    let response = await PostApi(reqparams1, 'DASHOARDLABEL');
    // console.log(response, "response")
    if (!response?.data?.docs.length > 0) {
      if(tenantData?.type=="political"){
        setIsPolitical(true)
      }
      const reqdata = {
        userid: userdata.id,
        ptype: 'USERSUBSCRIPTION',
        pageno: '-1',
        query: { userid: userdata.id }
      }

      const userSubsResp = await PostApi(reqdata, 'USERSUBSCRIPTION');
      if (userSubsResp?.data?.length > 0) {
        if (data?.on_sale == "N") {
          setCrt(true)
        }
        setSubscribed(true)
      } else {
        setSubscribed(false)
        setPurchased(false)
        setShow(true)
        setCrt(false)
        if (data?.on_sale == "N") {
          setCrt(true)
        }
        if(data.id=="660b92c3fa95f0377f1fe5f1"){
          setTcsEnroll(true)
         }

      }
    } else {
      handleClose()
      setPurchased(true)
    }

    return
  }
  else {
    return
  }
}

const openModal = () => {
  setModalUp(false)
}

const logIn = () => {

}
var previewFun = async (e) => {
  e.preventDefault();
  setItemModal(false);
  setShow(false)
  setShowStripeForm(false)
  setItem2(item)
  setRedirect(true)

var tntId= await JSON.parse(localStorage.getItem('tID'))

  const sessiondetails = await localStorage.getItem(`userdata${tntId}`);

  if (sessiondetails != null) {
    const userdata = JSON.parse(sessiondetails);
    if (item && (item != '' || item != null)) {
      if (item.on_sale == 'Y' && !item.ispurchased) {
        localStorage.setItem('previewtype', 'Y')
      }
      else if (item.on_sale == 'Y' && item.ispurchased) {
        localStorage.setItem('previewtype', 'Y')
      }
      else if (item?.canenroll == 'Y') {
        if (item.ispurchased || !item.ispurchased) {
          localStorage.setItem('previewtype', 'Y')
        }
      }
      else if (item?.canenroll == 'N') {
        localStorage.setItem('previewtype', 'N');
      }
    }
  }


}

if (redirect) {
  history.push({
    pathname: "/course-grid",
    state: item
  })
}

const handleProceed = async () => {
  try {
var tntId= await JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    if (sessiondetails != null) {
      const userdata = JSON.parse(sessiondetails);
      setUserData(userdata)




      const reqParams = { name: userdata.username, amount: Number(item.sale_price), userid: userdata.id, courseid: item.id, couponcode: CouponcodeNo };

      const reqdataCoupon = await GetApi(reqParams, "STRIPEKEY");
      // console.log(reqdataCoupon, "reqdataCoupon")

      if (reqdataCoupon.message == 'SUCCESS') {
        // console.log("this one is executing")
        setFinalAmount(Number(reqdataCoupon.amount))
        setConformation(true)
      } else if (reqdataCoupon.message == 'INVALIDCOUPONCODE') {
        toast.info('Invalid coupon code or you have already applied it , Please go back and retry again', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCouponcodeNo(0)

      } else if (reqdataCoupon.message == 'COUPONAMOUNTMORE') {
        toast.info('coupon amount value is more than the course amount please choose a different program', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCouponcodeNo(0)
      } else if (reqdataCoupon.message == 'FREECOURSE') {
        setEnrollFree(true)
      }



    }


  } catch (error) {
    console.log(error)
  }
}


const goBack = () => {
  history.push("/");
};


const handleFreeEnroll = async () => {

var tntId= await JSON.parse(localStorage.getItem('tID'))
  
  const sessiondetails = localStorage.getItem(`userdata${tntId}`);
  if (sessiondetails != null) {
    const userdata = JSON.parse(sessiondetails);

    const reqparams1 = {
      pageno: 1,
      query: { userid: userdata.id, programid: item.id },
      // query: { userid: '6574158e4c53154af2cc274a'},
      ptype: 'PROGRAMPURCHASES'
    }

    let response = await PostApi(reqparams1, 'DASHOARDLABEL');
    if (!response?.data?.docs.length > 0) {

      const reqdata = {
        programid: item.id,
        userid: userdata.id,
        couponcode: "0"
      }

      const resp = await PostApi(reqdata, 'ENROLL');
      if (resp.message == 'SUCCESS') {
        toast.success('Course enrollment successful,Please check your enrolled couse under my courses', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        history.push("/MyCourses")
      } else {

        toast.error('Failed to enroll your course', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        history.push("/")
      }
    } else {
      // setItem({ ...item, ispurchased: true })
      setPurchased(true)
    }

  }
}
  return (
    <>
    <div style={{width:"99%"}}>
    <div className='row px-5 mt-4' >
<div className='col-sm-12 col-md-8 col-lg-10 '>
<p className='course-header' >Get a Program of your choice</p>
</div>
<div className='col-sm-12 col-md-4 col-lg-2 d-flex justify-content-end course-header-btn'>
<Link to='/course-list'><button className='btn btn-primary py-2 px-3 fs-4 font-bold ' >View All</button></Link>
</div>
    </div>
    <div className='row p-2 mt-3'>

    {programs.length < 11 ?programs.map((ele,ind)=>{
      return(
<div className='col-sm-4 col-md-6 col-lg-3 p-4 ' onClick={() => { loginValidate(ele) }}>
<div class="card m-3 card-main-container" style={{width: "auto"}}>
  <span className='fs-6 px-2 bg-primary  text-light m-2 rounded' style={{width:"40%"}}><FaGraduationCap className='d-inline text-light fs-3' /> Programs</span>
  <img class="card-img-top px-2 rounded" style={{height:"170px",borderRadius:'10px !important'}} src={ele.image} alt="Card image cap" />
  <div class="card-body">
    <h4 class="card-title fs-4" style={{height:'48px'}}>{ele.name}</h4>
    <p class="card-text fs-5" style={{height:'120px'}}>{ele.description.length >150 ? ele.description.slice(0,150):ele.description}</p>

  </div>
  <div className='d-flex justify-content-between px-3 mb-2 pt-3 ' style={{borderTop:"1px solid rgb(211,211,211)"}}>
  <div className='fs-5 '> 0% off</div>
<div className='fs-5  text-decoration-line-through'>{ele.origin_price}</div>
<div className='fs-5 fw-bold text-danger' >RS.{ele.sale_price}</div>
  </div>
</div>
</div>
      )
    }) :new Array('1','2','3','4','5','6','7','8').map(ele=>{
      return(
   <>
   <div className='col-3 p-4'>
   <Skeleton className='mb-3' height={170} count={1} />
   <Skeleton className='mb-3' height={23} count={1} width={100} />
   <Skeleton className='mb-3' height={18} count={4} />
   </div>
   </>
      )
    })}
    </div>
    {modalUp && <LoginModal onClose={openModal} login={logIn}></LoginModal>}

    {item?.on_sale == 'Y' && !item.ispurchased && purchased == false && subscribed == false && <Modal show={show} onHide={handleClose} style={{ textAlign: 'center' }}>
<Modal.Header closeButton>
  <Modal.Title>Confirmation</Modal.Title>
</Modal.Header>
<Modal.Body>Please purchase the course to view the video content ?</Modal.Body>
<Modal.Footer>
  <Button variant="btn btn-outline-success" onClick={handlePurchase}>
    PURCHASE
  </Button>
  {/* <Link to='lms-page'> */}
  <Button variant="btn btn-outline-dark" onClick={previewFun}  >
    PREVIEW
  </Button>
  {/* </Link> */}

  <Button variant="btn btn-outline-dark" onClick={handleClose}>
    CANCEL
  </Button>
</Modal.Footer>
</Modal>}

{item?.on_sale == 'Y' && purchased && <Modal show={purchased} onHide={() => setPurchased(false)} style={{ textAlign: 'center' }}>
<Modal.Header closeButton>
  <Modal.Title>Purchase Confirmation</Modal.Title>
</Modal.Header>
<Modal.Body>'You have already purchased the course,You can check under My Courses or you can preview the same here'</Modal.Body>
<Modal.Footer>

  <button className="bg-dark p-2 rounded" >
    <Link className="text-light" to="/MyCourses">
      My Courses
    </Link>
  </button>
  {/* <Link to='lms-page'> */}
  <Button variant="btn btn-outline-dark" onClick={previewFun}  >
    PREVIEW
  </Button>
  {/* </Link> */}

  <Button variant="btn btn-outline-dark" onClick={() => setPurchased(false)}>
    CANCEL
  </Button>
</Modal.Footer>
</Modal>}
{item?.on_sale == 'N' && purchased && <Modal show={purchased} onHide={() => setPurchased(false)} style={{ textAlign: 'center' }}>
<Modal.Header closeButton>
  <Modal.Title>Purchase Confirmation</Modal.Title>
</Modal.Header>
<Modal.Body>'You have already purchased the course,You can check under My Courses or you can preview the same here'</Modal.Body>
<Modal.Footer>
  <button className="bg-dark p-2 rounded" >
    <Link className="text-light" to="/MyCourses">
      My Courses
    </Link>
  </button>
  {/* <Link to='lms-page'> */}
  <Button variant="btn btn-outline-dark" onClick={previewFun}  >
    PREVIEW
  </Button>
  {/* </Link> */}

  <Button variant="btn btn-outline-dark" onClick={() => setPurchased(false)}>
    CANCEL
  </Button>
</Modal.Footer>
</Modal>}

{item?.on_sale == 'Y' && subscribed && <Modal show={subscribed} onHide={() => setSubscribed(false)} style={{ textAlign: 'center' }}>
<Modal.Header closeButton>
  <Modal.Title>Program Enrollment</Modal.Title>
</Modal.Header>
<Modal.Body>'Enroll now and start your program training '</Modal.Body>
<Modal.Footer>
  <Button variant="btn btn-outline-dark" onClick={handleFreeEnroll}  >
    Enroll
  </Button>
  {/* <Link to='lms-page'> */}
  <Button variant="btn btn-outline-dark" onClick={previewFun}  >
    PREVIEW
  </Button>
  {/* </Link> */}

  <Button variant="btn btn-outline-dark" onClick={() => setSubscribed(false)}>
    CANCEL
  </Button>
</Modal.Footer>
</Modal>}





{tcsEnroll && purchased==false && <Modal show={tcsEnroll} onHide={() => setTcsEnroll(false)} style={{ textAlign: 'center' }}>
<Modal.Header closeButton>
  <Modal.Title>Program Enrollment</Modal.Title>
</Modal.Header>
<Modal.Body>'Enroll now and start your program training '</Modal.Body>
<Modal.Footer>
  <Button variant="btn btn-outline-dark" onClick={handleFreeEnroll}  >
    Enroll
  </Button>
  {/* <Link to='lms-page'> */}
  <Button variant="btn btn-outline-dark" onClick={previewFun}  >
    PREVIEW
  </Button>
  {/* </Link> */}

  <Button variant="btn btn-outline-dark" onClick={() => setTcsEnroll(false)}>
    CANCEL
  </Button>
</Modal.Footer>
</Modal>}







{item?.on_sale == 'N' && purchased == false && <Modal show={crt} onHide={() => setCrt(false)} style={{ textAlign: 'center' }}>
<Modal.Header closeButton>
  <Modal.Title>Program Enrollment</Modal.Title>
</Modal.Header>
<Modal.Body>'Enroll now and start your program training '</Modal.Body>
<Modal.Footer>
  <Button variant="btn btn-outline-dark" onClick={handleFreeEnroll}  >
    Enroll
  </Button>
  {/* <Link to='lms-page'> */}
  <Button variant="btn btn-outline-dark" onClick={previewFun}  >
    PREVIEW
  </Button>
  {/* </Link> */}

  <Button variant="btn btn-outline-dark" onClick={() => setCrt(false)}>
    CANCEL
  </Button>
</Modal.Footer>
</Modal>}

{tenantData.tenantid!="7d94b6c9"&&tenantData?.type == "political" && <Modal show={isPolitical} onHide={() => setIsPolitical(false)} style={{ textAlign: 'center' }}>
<Modal.Header closeButton>
  <Modal.Title>Program Enrollment</Modal.Title>
</Modal.Header>
<Modal.Body>'Enroll now and start your program training '</Modal.Body>
<Modal.Footer>
  <Button variant="btn btn-outline-dark" onClick={handleFreeEnroll}  >
    Enroll
  </Button>
  {/* <Link to='lms-page'> */}
  <Button variant="btn btn-outline-dark" onClick={previewFun}  >
    PREVIEW
  </Button>
  {/* </Link> */}

  <Button variant="btn btn-outline-dark" onClick={() => setIsPolitical(false)}>
    CANCEL
  </Button>
</Modal.Footer>
</Modal>}



{itemModal && <Modal show={itemModal} style={{ textAlign: 'center' }}>
<Modal.Header>
  <Modal.Title><h2>Discount Coupon</h2>
    <h6 className='m-3'>Enter discount coupon code  if you have any and cick on OK else just click Continue to proceed.</h6>
  </Modal.Title>
</Modal.Header>
<Modal.Body>
  <input onChange={(e) => { setCouponcodeNo(e.target.value) }} /> please enter coupon
</Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={() => { setItemModal(false) }}>
    CANCEL
  </Button>
  <Button variant="dark" onClick={() => { setCouponcodeNo(0); setItemModal(false); handleProceed() }}>
    Continue without coupon code
  </Button>
  <Button variant="primary" onClick={() => { setItemModal(false); handleProceed() }}>
    OK
  </Button>
</Modal.Footer>
</Modal>}

{confirmation && <Modal show={confirmation} style={{ textAlign: 'center' }}>
<Modal.Header>
  <Modal.Title><h2>Confirmation !!</h2>

  </Modal.Title>
</Modal.Header>
<Modal.Body>
  <h6>your are about to do a Payment of {finalAmount} Rupees for {item.name} program</h6>
</Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={() => { setConformation(false) }}>
    CANCEL
  </Button>

  <Button variant="primary" onClick={() => hashChangeEvent2()}>
    Proceed to pay
  </Button>
</Modal.Footer>
</Modal>}
{enrollFree && <Modal show={enrollFree} style={{ textAlign: 'center' }}>
<Modal.Header>
  <Modal.Title>You have succesfully applied the offer coupon code ,Please click on enroll now to purchase the free program</Modal.Title>
</Modal.Header>
{/* <Modal.Body>
  <input onChange={(e) => { setCouponcodeNo(e.target.value) }} /> please enter coupon
</Modal.Body> */}
<Modal.Footer>
  <Button variant="secondary" onClick={() => { setEnrollFree(false) }}>
    CANCEL
  </Button>
  <Button variant="primary" onClick={handleFreeEnroll}>
    OK
  </Button>
</Modal.Footer>
</Modal>}
{purchaseSUccessFull && <Modal show={purchaseSUccessFull} onHide={() => setPurchaseSUccessFull(false)} style={{ textAlign: 'center' }}>
<Modal.Header closeButton>
  <Modal.Title>Course Purchases SuccessFull</Modal.Title>
</Modal.Header>
<Modal.Body>'Please check your purchased course in MyCourses'</Modal.Body>
<Modal.Footer>
  <button className="bg-dark p-2 rounded" >
    <Link className="text-light" to="/MyCourses">
      My Courses
    </Link>
  </button>

</Modal.Footer>
</Modal>}
</div>
    </>
  )
}

export default HomePrograms3