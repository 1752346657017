import React,{useEffect, useState}from 'react'
import { CiBookmark } from "react-icons/ci";
import Accordion from 'react-bootstrap/Accordion';
import { PostApi } from '../services/CommonService2';
import DOMPurify from 'dompurify';

function Problems({problemContent}) {

  const [problemData, setProblemData] = useState([]);
useEffect(()=>{
getProblemData()
},[])
const getProblemData=async()=>{
    const reqData={
        pageno: '-1',
        query:{_id: problemContent.id}
    }
    const problemOutput = await PostApi(reqData,"COMPANYPROBLEMS");
    setProblemData(problemOutput.data)
}

const sanitizedData = (data) => {
    const sanitizedHtml=DOMPurify.sanitize(data)
    return {__html: sanitizedHtml}
  }

    return (
        <div className='m-0 p-0'>
       
           {problemData.map((ele,ind)=>{
            return(
              <>
                <p className='fs-4 fw-bold'>{ele.problem} </p>
                <div className='d-flex justify-content-between pt-3 pb-3 border-bottom border-2'>
                    <p className='fw-bold'>{ele.difficultylevel}</p>
                    <p className='fw-bold'>Accuracy: 16.5%</p>
                    <p className='fw-bold'>Submissions: 1.6M</p>
                    <p className='fw-bold'>Points:{ele.point}</p>
                </div>
                <div className='pt-3 pb-3'>
                    {ele.description}
                </div>
                <div>
                    <p className='fs-5 fw-bold'>Exampls:</p>
                    <div className='p-3 m-3 border border-2 rounded' style={{ backgroundColor: "#e5e4e2" }} dangerouslySetInnerHTML={sanitizedData(ele.examples)}>
                        
                    </div>
                
                </div>
              </>
            )
           })}

            {/* <div className=' '>
                <p className='fs-5 fw-bold'>Your Task:</p>
                <p className='p-3 ' style={{ fontSize: '16px' }}>
                    You don't need to read input or print anything. The task is to complete the function subarraySum() which takes arr, n, and s as input parameters and returns an array containing the starting and ending positions of the first such occurring subarray from the left where sum equals to s. The two indexes in the array should be according to 1-based indexing. If no such subarray is found, return an array consisting of only one element that is -1.
                </p>
                <p className='p-1'><b>Expected Time Complexity:</b> {` O(n)`}</p>
                <p className='p-1'><b>Expected Auxiliary Space:</b> {` O(1)`} </p>
            </div> */}
            {/* <div className='mt-4 py-3 px-3 border border-success rounded' style={{backgroundColor:"#f0fff0"}}>
                <div className='d-flex justify-content-between'>
                    <p style={{fontSize:"18px"}}>Seen this question in a real interview before ?</p>
                    <p style={{fontSize:"18px"}}>1/4</p>
                </div>
                <div className='d-flex justify-content-start mt-2'>
                    <button className='py-1 px-4 m-2' style={{fontSize:"18px",border:"1px solid green", color:"green",borderRadius:"30px",backgroundColor:"#f0fff0"}}>Yes</button>
                    <button className='py-1 px-4 m-2' style={{fontSize:"18px",border:"1px solid green", color:"green",borderRadius:"30px",backgroundColor:"#f0fff0"}}>No</button>
                </div>
            </div> */}

            <div className='mt-4'>

            <Accordion defaultActiveKey={['0']} alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header >Company Tags</Accordion.Header>
        <Accordion.Body >
<div className='d-flex flex-wrap'>
    {problemData.length >0 && problemData[0].companytags.map((ele,ind)=>{
return (
<span className=' py-2 px-4 m-2 border border-secondary rounded'>{ele.label}</span>
)
    })}

  
</div>
      
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Topic Tags</Accordion.Header>
        <Accordion.Body>
        <div className='d-flex flex-wrap'>
        {problemData.length >0 && problemData[0].topictags.map((ele,ind)=>{
return (
<span className=' py-2 px-4 m-2 border border-secondary rounded'>{ele.label}</span>
)
    })}
</div>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
            </div>
        </div>
    )
}

export default Problems