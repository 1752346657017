import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FaAngleDown, FaGreaterThan } from "react-icons/fa";
import { PostApi } from '../services/CommonService2';
import Problembar from './components/Problembar';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};
const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  maxHeight: '600px',
  overflow: "scroll",
};


function SideBar({ setSetSubTopicSelectedTags, setCompanySelectedTags, searchBarData, level, setLevel, homeSelectedCompany }) {
  const [companyNames, setCompanyNames] = useState([])
  const [topics, setTopics] = useState([])
  const [subTopics, setSubTopics] = useState([])
  const [viewAllopen, setViewAllOpen] = React.useState(false);
  const handleOpen = () => setViewAllOpen(true);
  const handleClose = () => setViewAllOpen(false);
  const [companyPopUpCheck, setCompanyPopUpCheck] = useState([])
  const [toggleBookMark, setToggleBookMark] = useState(false);
  const [bookmarkedProblems, setBookmarkedProblems] = useState([])


  useEffect(() => {
    getCompanyName()
    getTopics()
    getSubTopics()
    homeSelectedCompanyFunc()
  }, [])

  useEffect(() => {
    checkCompanyPopUp()
  }, [companyPopUpCheck, viewAllopen])


  useEffect(() => {

    unCheckElements()


  }, [searchBarData])


  const homeSelectedCompanyFunc = () => {
    var data=[]
    if (homeSelectedCompany?.value != "") {
      data.push(homeSelectedCompany.value)
      var selectedElements = document.getElementsByName("company");

     setTimeout(()=>{
      console.log(selectedElements.length,"Augast")
      for (var i = 0; i < selectedElements.length; i++) {
        if (selectedElements[i].value === homeSelectedCompany?.value) {
          selectedElements[i].checked = true
        }
      }
      for (var i = 0; i < selectedElements.length; i++) {
      
        for (var j = 0; j < data.length; j++) {
          if (data[j] == selectedElements[i].value)
     
            selectedElements[i].checked = true;
         
        }
  
      }
     },500)
      console.log(selectedElements, " selectedElements selectedElements selectedElements selectedElements ")

      setCompanyPopUpCheck(data)
      setCompanySelectedTags(data)
    }

  }
  const getCompanyName = async () => {
    var tntId = JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    const userdata = JSON.parse(sessiondetails);


    if (sessiondetails != null) {
      const reqparam = {
        pageno: '-1',
        query: { status: "Active" },

      }


      const response = await PostApi(reqparam, 'COMPANYNAMES');
      setCompanyNames(response.data)

    }
  }
  const getTopics = async () => {
    var tntId = JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    const userdata = JSON.parse(sessiondetails);


    if (sessiondetails != null) {
      const reqparam = {
        pageno: '-1',
        query: { status: "Active" },

      }


      const response = await PostApi(reqparam, 'COMPANYTOPICS');

      setTopics(response.data)

    }
  }
  const getSubTopics = async () => {
    var tntId = JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    const userdata = JSON.parse(sessiondetails);


    if (sessiondetails != null) {
      const reqparam = {
        pageno: '-1',
        query: { status: "Active" },

      }


      const response = await PostApi(reqparam, 'COMPANYSUBTOPICS');
      console.log(response, 'success');
      setSubTopics(response.data)

    }
  }
  var subTopicCheckItem = []
  const handleSubTopicCheckboxChange = (event) => {
    subTopicCheckItem = []

    var selectedElements = document.getElementsByName("filter")
    for (var i = 0; i < selectedElements.length; i++) {
      if (selectedElements[i].checked) {
        subTopicCheckItem.push(selectedElements[i].value)
      }

    }
    setSetSubTopicSelectedTags(subTopicCheckItem)
  };

  var companyCheckItem = []
  const handleCompanyCheckboxChange = (event) => {
    companyCheckItem = [];
    var selectedElements = document.getElementsByName("company")
    for (var i = 0; i < selectedElements.length; i++) {
      if (selectedElements[i].value === event.target.value) {
        selectedElements[i].checked = event.target.checked
      }
    }

    for (var i = 0; i < selectedElements.length; i++) {
      if (selectedElements[i].checked) {
        companyCheckItem.push(selectedElements[i].value)
      }
    }
    var uniqueCompanyCheckItems = [...new Set(companyCheckItem)]
    setCompanyPopUpCheck(uniqueCompanyCheckItems)
    setCompanySelectedTags(uniqueCompanyCheckItems)


  }

  var levelCheckItem = []
  const handleLevel = () => {
    levelCheckItem = [];
    var selectedElements = document.getElementsByName("level")
    for (var i = 0; i < selectedElements.length; i++) {
      if (selectedElements[i].checked) {
        levelCheckItem.push(selectedElements[i].value)
      }
    }
    setLevel(levelCheckItem)
  }

  var unCheckElements = () => {
    var company = document.getElementsByName("company")
    var level = document.getElementsByName("level")
    var subTpic = document.getElementsByName("filter")
    if (searchBarData != "") {
      for (var i = 0; i < company.length; i++) {
        company[i].checked = false;

      }
      for (var i = 0; i < subTpic.length; i++) {
        subTpic[i].checked = false;
      }
      for (var i = 0; i < level.length; i++) {
        level[i].checked = false;
      }
    }


  }
  const checkCompanyPopUp = () => {

    var popUpcheckItems = document.getElementsByName("company");

    for (var i = 0; i < popUpcheckItems.length; i++) {
      for (var j = 0; j < companyPopUpCheck.length; j++) {
        if (companyPopUpCheck[j] == popUpcheckItems[i].value)
          popUpcheckItems[i].checked = true;
       
      }

    }

  }


  const toggleBookMarkFunc = () => {
    var bookmark = document.getElementsByName("bookmark");
    if (bookmark[0].checked) {
      setToggleBookMark(true)
      getBookMarkedUserProblems()
    }

  }

  const closeBookMarkToggle = () => {
    document.getElementsByName("bookmark")[0].checked = false;
    setToggleBookMark(false)
    window.location.reload()
  }



  const getBookMarkedUserProblems = async () => {
    var tntId = JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    if (sessiondetails != null) {
      const userdata = JSON.parse(sessiondetails);
      const reqParams = {
        pageno: -1,
        query: {
          userid: userdata.userid,
        }

      }
      const response = await PostApi(reqParams, 'USERSPRINTGET')
      var uniqueUserSprints = [...new Set(response.data.docs[0].problemid)]
      const params = {
        pageno: -1,
        query: {
          _id: {
            $in: uniqueUserSprints,
          }
        }
      }
      const bookmsrkresponse = await PostApi(params, 'COMPANYPROBLEMS');
      setBookmarkedProblems(bookmsrkresponse.data)
    }
  }
  return (
    <div className='p-4'>
      <Modal
        open={toggleBookMark}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="large"
      >
        <Box sx={style2}>
          <div>
            <h4 className='my-4 text-center text-success'>Bookmarked Problems</h4>
            {bookmarkedProblems.length > 0 ? bookmarkedProblems.map((ele, ind) => {
              return (
                <Problembar ele={ele} />
              )
            }) : <div className='text-primary fs-5' style={{ height: "30%", textAlign: "center" }}> No Bookmarks</div>}
          </div>
          <div className='d-flex justify-content-end'>
            <button className='btn btn-danger mt-4' onClick={() => closeBookMarkToggle()}>Close</button>

          </div>
        </Box>
      </Modal>
      <Modal
        open={viewAllopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='row'>
            <h5 className='mb-4'> All Companies</h5>
            {companyNames.map((ele, ind) => {
              return (
                <label className='text-dark col-lg-4 col-md-6 col-sm-12'>  <input // prettier-ignore
                  className='text-light mb-2'
                  type="checkbox"
                  id={ele.name}
                  value={ele.name}
                  name='company'
                  checked={companyPopUpCheck.filter(item => item == ele.name)[0]}
                  onChange={handleCompanyCheckboxChange}
                /> {ele.name}
                </label>
              )
            })}

          </div>
          <div className='d-flex justify-content-end'>
            <button className='btn btn-danger mt-4' onClick={() => setViewAllOpen(false)}>Close</button>

          </div>
        </Box>
      </Modal>
      <h5 className='text-light mt-4 mb-4'>Filters</h5>
      <div className='mt-4 mb-4 d-flex justify-content-between'>
        <div>
          <form >
            <h6 className='text-light'>COMPANIES</h6>
            {companyNames.map((ele, ind) => {
              if (ind < 3) {
                return (
                  <label className='text-light d-block mt-1'>  <input // prettier-ignore
                    className='text-light mb-2'
                    type="checkbox"
                    id={ele.name}
                    value={ele.name}
                    name='company'
                    onChange={handleCompanyCheckboxChange}
                  /> {ele.name}
                  </label>
                )
              }
            })}
          </form>


        </div>

        <p className='text-success' style={{ fontSize: "18px", cursor: "pointer" }} onClick={handleOpen}>View All</p>
      </div>



      <h6 className='text-light mt-3'>Topics</h6>
      {/* <Form className='mt-3'>

        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="SCHOOL"
          label={`DSA`}
        />
        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="BASIC"
          label={`Algorithms`}
        />
      
   
      

      </Form> */}
      <div className='mt-1 w-75'>
        {topics.map((ele) => {
          return (
            <Accordion className='text-light' style={{ backgroundColor: '#3b444b' }}>
              <AccordionSummary
                expandIcon={<FaAngleDown className='text-light fs-3' />}
                aria-controls="panel1-content"
                id="panel1-header"

              >
                <p> {ele.name}</p>
              </AccordionSummary>
              <AccordionDetails>
                {subTopics.filter(item => item.topicid == ele.id).map(data => {
                  return (
                    <div className='mt-2'>
                      <label>
                        <input
                          type="checkbox"
                          name="filter"
                          value={data.name}
                          onChange={handleSubTopicCheckboxChange}
                        /> {data.name}
                      </label>
                    </div>
                  )
                })}
              </AccordionDetails>
            </Accordion>
          )
        })}


      </div>


      <h6 className='text-light mt-3'>DIFFICULTY</h6>
      <Form className='mt-3'>

        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="Low"
          name="level"
          label={`Low`}
          value="Low"
          onChange={handleLevel}
        />
        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="Medium"
          label={`Medium`}
          value="Medium"
          name="level"
          onChange={handleLevel}
        />
        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="High"
          label={`High`}
          value="High"
          name="level"
          onChange={handleLevel}
        />


      </Form>



      <div className='mt-4 mb-4 d-flex justify-content-between'>
        <div>
          <h6 className='text-light'>MY SPRINT</h6>
          <Form className='mt-3'>

            <Form.Check // prettier-ignore
              className='text-light mb-2'
              type="checkbox"
              id="BOOKMARK"
              label={`BOOKMARK`}
              name='bookmark'
              onChange={toggleBookMarkFunc}
            />


          </Form>
        </div>

        {/* <p className='text-success' style={{ fontSize: "18px" }}>View All <FaGreaterThan className='bg-success text-light p-2' style={{ fontSize: "22px", borderRadius: "50%" }} /></p> */}
      </div>

      {/* <div className='mt-4 mb-4 d-flex justify-content-between'>
        <div>
          <h6 className='text-light'>FEATURED SPRINTS</h6>
          <Form className='mt-3'>

            <Form.Check // prettier-ignore
              className='text-light mb-2'
              type="radio"
              id="SPRINTS"
              name='SPRINTS'
              label={`SDE Sheet`}
            />
            <Form.Check // prettier-ignore
              className='text-light mb-2'
              type="radio"
              id="SPRINTS"
              name='SPRINTS'
              label={`Begineer's DSA Sheet`}
            />
            <Form.Check // prettier-ignore
              className='text-light mb-2'
              type="radio"
              id="SPRINTS"
              name='SPRINTS'
              label={`LOVE Babbar Sheet`}
            />

          </Form>
        </div>

        <p className='text-success' style={{ fontSize: "18px" }}>View All <FaGreaterThan className='bg-success text-light p-2' style={{ fontSize: "22px", borderRadius: "50%" }} /></p>
      </div> */}

    </div>
  )
}

export default SideBar