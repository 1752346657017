import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { PostApi } from '../../services/CommonService2';
import { FaBookmark } from "react-icons/fa";
import { CiBookmark } from "react-icons/ci";
function Problembar({ ele }) {
    const [userSprints, setUserSprints] = useState([])
    useEffect(() => {
        getUserSprints()
    }, [])

    const getUserSprints = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const sessiondetails = localStorage.getItem(`userdata${tntId}`);
        if (sessiondetails != null) {
            const userdata = JSON.parse(sessiondetails);
            const reqParams = {
                pageno: -1,
                query: {
                    userid: userdata.userid,
                }

            }
            const response = await PostApi(reqParams, 'USERSPRINTGET')
            var uniqueUserSprints=[...new Set(response.data.docs[0].problemid)]
            setUserSprints(uniqueUserSprints)
           
        }
    }

    const saveUserSprints = async (req, res) => {

        var tntId = JSON.parse(localStorage.getItem('tID'))

        const sessiondetails = localStorage.getItem(`userdata${tntId}`);
        if (sessiondetails != null) {
            const userdata = JSON.parse(sessiondetails);
            var reqParams = {
                pageno: -1,
                query: {
                    userid: userdata.id,
                    problemids: ele.id,
                }

            }
            const response = await PostApi(reqParams, 'USERSPRINTSAVE')
            if (response.status == 200) {
                getUserSprints()
            }
        }
    }

   const deleteUserSprints =async()=>{
    var tntId = JSON.parse(localStorage.getItem('tID'))
    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    if (sessiondetails != null) {
        const userdata = JSON.parse(sessiondetails);
        var reqParams = {
            pageno: 1,
            query: {
                userid: userdata.id,
                problemids: ele.id,
            }

        }
        const response = await PostApi(reqParams, 'USERSPRINTDELETE');
        console.log(response,"response response response")
        if (response.status == 200) {
            getUserSprints()
        }
    }
   }
    return (
        <div className='row pt-4 pb-4 m-0' style={{ borderTop: "1px solid gray" }}>
            
            <div className='col-lg-9'>
                <h4 className='mb-2 '>{ele.problem} <span className='d-inline-block'>{userSprints.includes(ele.id) ? <FaBookmark className='fs-4 text-success' onClick={deleteUserSprints} /> : <CiBookmark onClick={saveUserSprints} className='fs-4 ' />}</span></h4>
                {ele.companytags.map(item => {
                    return (
                        <span className='p-2 text-success'>{item.label}</span>
                    )
                })}

            </div>
            <div className='col-lg-3'>
                <Link to={{ pathname: "/taketest", state: ele }} className='btn btn-success fs-5 px-5'> Solve Problem</Link>
                <div className='mt-1 d-flex justify-content-center'>
                    <p className='m-2'>{ele.difficultylevel}</p>
                    <p className='m-2 px-2' style={{ borderRight: "1px solid gray", borderLeft: "1px solid gray" }}>2M</p>
                    <p className='m-2'>16.5%</p>
                </div>
            </div>
        </div>
    )
}

export default Problembar