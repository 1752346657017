import React, { useState } from 'react'
import { FaUser } from "react-icons/fa";
import DOMPurify from 'dompurify';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FaAngleDown } from "react-icons/fa";
import ReactQuill from 'react-quill';
import { PostApi } from '../services/CommonService2';
function UserComment({ comment, getAllComments }) {
  const [value, setValue] = useState("")

  const modules = {
    toolbar: [
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // ["bold", "italic", "underline", "strike", "blockquote"],
      // [{ size: [] }],
      // [{ font: [] }],
      // [{ align: ["right", "center", "justify"] }],
      // [{ list: "ordered" }, { list: "bullet" }],
      // ["link", "image"],
      // [{ color: ["red", "#785412"] }],
      // [{ background: ["red", "#785412"] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "align",
    "font",
  ];

  const sanitizedData = (data) => {
    const sanitizedHtml = DOMPurify.sanitize(data)
    return { __html: sanitizedHtml }
  }

  const handleReply = (e) => {
    setValue(e)
  }

  const handlePostReply = async () => {
    var tntId = await JSON.parse(localStorage.getItem('tID'))
    const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
    if (userdata != null) {
      var data = {
        username: userdata.user.name,
        usercomment: value,
        userid: userdata.id,
        problemid: comment.problemid,
        commentid: comment.id,
        userimage: userdata.user.image,
        sortwith:  new Date().getTime(),
      }

      const postReply = await PostApi(data, "CODECOMMENTSUPDATE")
      if (postReply.status == 200) {
        getAllComments()
      }
    }
    setValue("")
  }
  return (
    <div className=' mb-3'>





      {/* style={{ backgroundColor: "#e5e4e2" }}  */}
      <div className=' border border-2 rounded m-1 p-3' >
        <h6 className='d-inline-block'>  {comment.userimage ? <img src={`data:image/jpeg;base64,${comment.userimage}`} className='d-inline-block' style={{ borderRadius: "50%", height: "25px", width: "25px" }} /> : <FaUser className='fs-3  border border-dark border-2 rounded-circle text-dark d-inline' />} {comment.username}</h6> <span className='m-3 fw-bold d-inline'>9 months ago</span>
        <div className='m-2' dangerouslySetInnerHTML={sanitizedData(comment?.comment)}></div>  <div>
          <div className='d-flex justify-content-end'>
            <Accordion sx={{
              width: '80%',
              backgroundColor: 'transparent',
              marginTop: "-12px",
              boxShadow: 'none',

            }}>
              <AccordionSummary
                expandIcon={<FaAngleDown style={{ color: "blue" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ display: "flex", justifyContent: "end", position: "relative" }}
              >
                <Typography sx={{ color: "blue", textAlign: "right", position: "absolute", right: "31px", padding: "0px", marginTop: "-10px" }}> Reply</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ marginTop: "-25px" }}>
                <Typography className='row'>
                  <ReactQuill className='react-quill col-10 border-none mb-3 ' theme="snow" 
                  placeholder='Reply . . . .'
                  modules={{ toolbar: false }}
                  formats={formats}
                    onChange={handleReply}
                    value={value}
                   
                  />
                  <button className='col-2 btn btn-success' style={{ height: "70%", marginTop: "4%" }} onClick={handlePostReply}>Post</button>
                 
                </Typography>
                {comment.replies.sort((a,b)=>b?.replysortwith - a?.replysortwith).map((ele, ind) => {
                    return (
                      <div className='mx-3 mt-1'>
                        <p className='d-inline-block fw-bold'>  {ele.replyuserimage ? <img src={`data:image/jpeg;base64,${ele.replyuserimage}`} className='d-inline-block' style={{ borderRadius: "50%", height: "18px", width: "18px" }} /> : <FaUser className='fs-5   border-dark border-2 rounded-circle text-dark d-inline'  />} {ele.replyusername}</p>
                        <div className='mx-4 my-2' dangerouslySetInnerHTML={sanitizedData(ele?.replyusercomment)}></div>
                      </div>
                    )
                  })}
                
              </AccordionDetails>
            </Accordion>
          </div>

        </div>

      </div>
    </div>
  )
}

export default UserComment