import React, { useState } from 'react'
import { FaBars } from "react-icons/fa";
import HeaderTwo from '../components/HeaderTwo';
import FooterTwo from '../components/FooterTwo';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FaCode } from "react-icons/fa";
import { LuFileText } from "react-icons/lu";
import { BiTimeFive } from "react-icons/bi";
import { FaRegComment } from "react-icons/fa6";
import Landing from '../pages/coding-test/components/Landing';
import Problems from './Problems';
import Editorial from './Editorial';
import Submissions from './Submissions';
import Comments from './Comments';
import LandingTwo from '../pages/coding-test/components/LandingTwo';
import QuestionBar from './QuestionBar';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const theme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: 'green', // Set the indicator color to green
                },
            },
        },
    },
});
function TakeTest() {
    const problemdata=useLocation()

    const [value, setValue] = useState('1');
    const [toggle,setToggle] =useState(false);
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <HeaderTwo />
            <div className='row m-0 p-0'>
                <div className='col-lg-6'>
                    <div className='p-2' style={{ backgroundColor: "#f2f3f4" }}>
                        <div className='d-flex ' style={{position:"relative"}}>
                            <FaBars className='fs-2 m-3' onClick={()=>setToggle(!toggle)}  />
                            <QuestionBar toggle={toggle} setToggle={setToggle}  />
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <FaCode className='fs-5 m-2' />
                                                    Problem
                                                </Box>
                                            } value="1" sx={{
                                                width: "25%",
                                                fontSize: '13px',
                                                color: value === '1' ? 'success.main' : 'black',
                                            }} />
                                            <Tab label={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <LuFileText className='fs-5 m-2' />
                                                    Editorial
                                                </Box>
                                            } value="2" sx={{
                                                width: "25%",
                                                fontSize: '13px',
                                                color: value === '2' ? 'success.main' : 'black',
                                            }} />
                                            <Tab label={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <BiTimeFive className='fs-5 m-2' />
                                                    Submission
                                                </Box>
                                            } value="3" sx={{
                                                width: "25%",
                                                fontSize: '13px',
                                                color: value === '3' ? 'success.main' : 'black',
                                            }} />
                                            <Tab label={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <FaRegComment className='fs-5 m-2' />
                                                    Comments
                                                </Box>
                                            } value="4" sx={{
                                                width: "25%",
                                                fontSize: '13px',
                                                color: value === '4' ? 'success.main' : 'black',
                                            }} />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <Problems problemContent={problemdata.state}  />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Editorial problemContent={problemdata.state} />
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <Submissions problemContent={problemdata.state} />
                                    </TabPanel>
                                    <TabPanel value="4">
                                        <Comments problemContent={problemdata.state} />
                                    </TabPanel>
                                </TabContext>
                            </Box>

                        </div>

                    </div>
                </div>
                <div className='col-lg-6'>
                 <LandingTwo problemContent={problemdata.state} />
                </div>
            </div>
            <FooterTwo />
        </>
    )
}

export default TakeTest