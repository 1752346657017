import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IoSend } from "react-icons/io5";
import UserComment from './UserComment';
import "./comment.css"
import { PostApi } from '../services/CommonService2';
import "./commentstyle.css"
function Comments({ problemContent }) {
    useEffect(() => {
        getAllComments()
    }, [])
    const [value, setValue] = useState('');
    const [allComments, setAllComments] = useState([]);

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "image",
        "align",
        "font",
      ];

    const saveComments = async () => {
        var tntId = await JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if (userdata != null) {
            var data = {
                problemid: problemContent.id,
                username: userdata.user.name,
                comment: value,
                userid: userdata.id,
                userimage: userdata.user.image,
                sortwith: new Date().getTime(),
            }
            console.log(value,"value valuevalue valuevalue valuevalue ")
            // if(value.trim()!=""){
            //     const saveUserComment = await PostApi(data, 'CODECOMMENTSSAVE');
            //     if (saveUserComment.status == 200) {
            //         setValue("");
            //         getAllComments();
            //     }
            // }
            
        }
    }
    const getAllComments = async () => {
        const reqparams = {
            pageno: -1,
           query:{ problemid: problemContent.id}
        }
        const response = await PostApi(reqparams, 'CODECOMMENTSGET');
        let sortResponse =response.data.sort((a, b) => b?.sortwith-a?.sortwith)
        setAllComments(sortResponse);
    }
    return (
        <>
            <div className='d-flex justify-content-between'>
                {/* <h6>Discussions
                    {`( 3927 + Threads )`}</h6> */}
                {/* <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Search By
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Most Recent</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Lease Recent</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Most Rated</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
            </div>
            <div className='p-2'>
                <ReactQuill className='react-quill' theme="snow" placeholder='Wrire Your Comment . . .'
                 modules={{ toolbar: false }}
                 formats={formats} 
                    value={value} onChange={setValue} />
                <div className='d-flex justify-content-end mt-3' onClick={saveComments}>
                    <button className='btn btn-success'>Post Comment <IoSend className='d-inline' /></button>
                </div>
                <div className='comments mt-4' >
                    {
                        allComments.map((ele) => {
                            return <UserComment comment={ele} getAllComments={getAllComments} />
                        })
                    }

                </div>
            </div>
        </>
    )
}

export default Comments