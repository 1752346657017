import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginModal from '../../../components/LoginModal';
import { PostApi } from '../../../services/CommonService2';
import "./component-style.css"
const StateItemGrid = ({ setSearchData,searchData, stateData, setdata }) => {


    const data = useLocation();
    const [course, setCourse] = useState([])
    const [courselist, setCourselist] = useState([]);
    const [modalUp, setModalUp] = useState(false)
    const history = useHistory();
    const didMountRef = useRef(false);
    const [totalRecords, setTotalRecords] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {
        const searchFun = async () => {
            var tntId = JSON.parse(localStorage.getItem('tID'))
            const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
            if (userdata?.user.subapplicationid ) {


                var reqparams1 = ''
                if (searchData != "") {
                    reqparams1 = {
                        pageno: 1,
                        query: {
                            status: 'Active',
                            name: { $regex: searchData, $options: "i" },
                            criteria: { $elemMatch: { "branch": stateData.branchid, "semister": stateData.semisterid, "year": stateData.academictypeid,  } },
                            area: {
                                $elemMatch: { "value": userdata?.user?.subapplicationid }
                              }

                        }
                    }
                }
                else if (stateData != '') {
                    reqparams1 = {
                        pageno: 1,
                        query: {
                            status: 'Active',
                            criteria: { $elemMatch: { "branch": stateData.branchid, "semister": stateData.semisterid, "year": stateData.academictypeid } },
                            area: {
                                $elemMatch: { "value": userdata?.user?.subapplicationid }
                            }
                        }
                    }
                }
                else {
                    reqparams1 = {
                        pageno: 1,
                        query: {
                            status: '',
                        }
                    }
                }

            }
            else {


                var reqparams1 = ''
                if (searchData != "") {
                    reqparams1 = {
                        pageno: 1,
                        query: {
                            status: 'Active',
                            name: { $regex: searchData, $options: "i" },
                            criteria: { $elemMatch: { "branch": stateData.branchid, "semister": stateData.semisterid, "year": stateData.academictypeid } }

                        }
                    }
                }
                else if (stateData != '') {
                    reqparams1 = {
                        pageno: 1,
                        query: {
                            status: 'Active',
                            criteria: { $elemMatch: { "branch": stateData.branchid, "semister": stateData.semisterid, "year": stateData.academictypeid} }
                        }
                    }
                }
                else {
                    reqparams1 = {
                        pageno: 1,
                        query: {
                            status: '',
                        }
                    }
                }
            }

            // console.log("searchData===", searchData, stateData);
            const action = "ACADEMICCOURSES";

            const ResponseData1 = await PostApi(reqparams1, action);

            setCourselist(ResponseData1?.data?.docs);

            setdata(ResponseData1?.data?.docs)
            setTotalRecords(ResponseData1?.data?.totalDocs)
            // setSearchData("")
            // console.log('catttt', ResponseData1);
        }
        searchFun();
    }, [searchData, currentPage, stateData])

    const loginValidate = (data) => {
        localStorage.removeItem('edutype');
        localStorage.setItem("edutype", JSON.stringify('ACADEMICDETAILS'))
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if (userdata == null) {
            setModalUp(true)
            toast.info('login to check course details', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            localStorage.setItem('edutype', JSON.stringify('ACADEMICDETAILS'));
            history.push({
                pathname: "/lms-page",
                state: data
            })
        }

    }

    const openModal = () => {
        setModalUp(false)
    }

    const logIn = () => {

    }

    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const npage = Math.ceil(totalRecords / recordsPerPage)
    const records = courselist?.slice(firstIndex, lastIndex)
    // console.log("error data",[...Array(npage + 1).keys()].slice(1),npage);
    // console.log("total records",totalRecords);
    const numbers = [...Array(npage + 1).keys()].slice(1)


    const prePage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }


    }
    const changeCPage = (id) => {
        setCurrentPage(id)

    }
    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)

        }
    }
    const goBack = () => {
        history.push("/");
    };

    return (
        <>
      
                
                   
            <button className="btn btn-primary" onClick={goBack} style={{ position: "fixed", bottom: "20px", left: "10px", zIndex: 999, width: "30px" }}><i class="fa-solid fa-arrow-left"></i></button>

            <Fragment>

                {
                    courselist?.map((data, i) => {

                        let imageid = 'https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                        if (data?.image) {
                            imageid = data.image;
                        } else if (data?.image_url) {
                            imageid = data.image_url;
                        }
                        else {
                            imageid = data.imageurl ? data.imageurl : "";
                        }

                        return <Col lg="3" key={i} className='p-4' >
                            <div className="course-item cursor-pointer card-hover" onClick={() => loginValidate(data)}>

                                <div className="course-image">
                                    <div >
                                        <img src={imageid} className="course-image"></img>

                                    </div>
                                </div>

                                {/* <div className="course-content border-2">
                                    <h6 className="heading m-3"style={{color:"black"}}>{data.name}</h6>
                                    <p className="desc px-3 pb-3">{data.description.slice(0, 100)}</p>
                                    <div className="course-face d-flex justify-content-between">
                                    </div>
                                </div> */}
                            </div>
                        </Col>
                    })
                }
                {/* {
                errorMsg && <div style={{ textAlign: "center" }}><h1 style={{ fontFamily: "cursive", color: "red",marginBottom:"15px" }}>Oopps !!</h1>
                <h4>No Course found</h4></div>
                } */}

                {courselist?.length > 0 && numbers?.length>1 ? <Col md="12" className="text-center">
                    {/* <Pagination /> */}
                    <div className='mainpagination'>
                        <ul className='pagination'>
                            <li className='page-item'>
                                <label className=' btn btn-outline-success page-link' onClick={prePage}><i class="fa-solid fa-angles-left"></i></label>

                            </li>
                            {numbers.map((n, i) => (
                                <li className={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                    <label className='btn btn-outline-success page-link' onClick={() => changeCPage(n)}>{n}</label>

                                </li>

                            ))}

                            <li className='page-item'>
                                <label className='btn btn-outline-success page-link' onClick={nextPage}><i class="fa-solid fa-angles-right"></i></label>

                            </li>


                        </ul>
                    </div>
                </Col> : ""}

                {modalUp && <LoginModal onClose={openModal} login={logIn}></LoginModal>}
                {/* <ToastContainer /> */}

            </Fragment>
        </>)

}
export default StateItemGrid
