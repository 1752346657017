import axios from "axios";
import React, { useEffect, useState } from "react";
import { languageOptions } from "../constants/languageOptions";
import CodeEditorWindow from "./CodeEditorWindow";

import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PostApi } from "../../../services/CommonService2";
import '../AppCode.css';
import useKeyPress from "../hooks/useKeyPress";
import '../indexCode.css';
import { defineTheme } from "../lib/defineTheme";
import CustomInput from "./CustomInput";
import LanguagesDropdown from "./LanguagesDropdown";
import OutputDetails from "./OutputDetails";
import OutputWindow from "./OutputWindow";
import ThemeDropdown from "./ThemeDropdown";
const javascriptDefault = `/**
* Practice Coding here ...
*/


`;
function LandingTwo({problemContent}) {
    const [code, setCode] = useState(javascriptDefault);
    const [customInput, setCustomInput] = useState("");
    const [outputDetails, setOutputDetails] = useState(null);
    const [processing, setProcessing] = useState(null);
    const [theme, setTheme] = useState("cobalt");
    const [language, setLanguage] = useState(languageOptions[0]);
  const[showOutput, setShowOutput] = useState(false);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [startCoding, setSaterCoding]=useState(false);
  const [startTime, setStartTime] = useState('');

    const enterPress = useKeyPress("Enter");
    const ctrlPress = useKeyPress("Control");
  
    const onSelectChange = (sl) => {
      console.log("selected Option...", sl);
      setLanguage(sl);
    };
  
    useEffect(() => {
      if (enterPress && ctrlPress) {
        console.log("enterPress", enterPress);
        console.log("ctrlPress", ctrlPress);
        handleCompile();
      }
    }, [ctrlPress, enterPress]);
    const onChange = (action, data) => {
      switch (action) {
        case "code": {
          setCode(data);
          break;
        }
        default: {
          console.warn("case not handled!", action, data);
        }
      }
    };
    const handleCompile = () => {
     
      setProcessing(true);
      const formData = {
        language_id: language.id,
        // encode source code in base64
        source_code: btoa(code),
        stdin: btoa(customInput),
      };
      const options = {
        method: "POST",
        url: 'https://judge0-ce.p.rapidapi.com/submissions',
        params: { base64_encoded: "true", fields: "*" },
        headers: {
          "content-type": "application/json",
          "Content-Type": "application/json",
          "X-RapidAPI-Host":'judge0-ce.p.rapidapi.com',
          "X-RapidAPI-Key": '90673ae240msh991294ddcaf7d4bp13261ajsn42c11ca2b2ea',
        },
        data: formData,
      };
  
      axios
        .request(options)
        .then(function (response) {
          console.log("res.data", response.data);
          const token = response.data.token;
          checkStatus(token);
        })
        .catch((err) => {
            setShowOutput(true)
          console.log(err, "check error of the request")
          let error = err.response ? err.response.data : err;
          // get error status
          let status = err.response?.status;
          console.log("status check", status);
          if (status === 429) {
            console.log("too many requests", status);
  
            showErrorToast(
              `Quota of 100 requests exceeded for the Day! Please read the blog on freeCodeCamp to learn how to setup your own RAPID API Judge0!`,
              10000
            );
          }
         
          setProcessing(false);
          
          console.log("catch block...", error);
        });
    };
  
    const checkStatus = async (token) => {
      const options = {
        method: "GET",
        url:'https://judge0-ce.p.rapidapi.com/submissions' + "/" + token,
        params: { base64_encoded: "true", fields: "*" },
        headers: {
          "X-RapidAPI-Host":'judge0-ce.p.rapidapi.com',
          "X-RapidAPI-Key": '90673ae240msh991294ddcaf7d4bp13261ajsn42c11ca2b2ea',
        },
      };
      try {
        let response = await axios.request(options);
        console.log("response await",response)
        let statusId = response.data?.status?.id;
  
        // Processed - we have a result
        if (statusId === 1 || statusId === 2) {
          // still processing
          setTimeout(() => {
            checkStatus(token);
          }, 2000);
          return;
        } else {
          setProcessing(false);
          setShowOutput(true)
          setOutputDetails(response.data);
          showSuccessToast(`Compiled Successfully!`);
          console.log("response.data", response.data);
          return;
        }
      } catch (err) {
        console.log("err", err);
        setProcessing(false);
        setShowOutput(true)
        showErrorToast();
      }
    };
  
    function handleThemeChange(th) {
      const theme = th;
      console.log("theme...", theme);
  
      if (["light", "vs-dark"].includes(theme.value)) {
        setTheme(theme);
      } else {
        defineTheme(theme.value).then((_) => setTheme(theme));
      }
    }
    useEffect(() => {
      defineTheme("oceanic-next").then((_) =>
        setTheme({ value: "oceanic-next", label: "Oceanic Next" })
      );
    }, []);
  
    const showSuccessToast = (msg) => {
      toast.success(msg || `Compiled Successfully!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    const showErrorToast = (msg, timer) => {
      toast.error(msg || `Something went wrong! Please try again.`, {
        position: "top-right",
        autoClose: timer ? timer : 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
  const handleStartCoding = ()=>{
    setSaterCoding(true)
    setStartTime(new Date())
  }

  const handleSubmitCode=async()=>{
    var tntId= await JSON.parse(localStorage.getItem('tID'))

    const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))

    if (userdata != null) {
    var data={
      problemid:problemContent.id,
      userid:userdata.id,
      code:code,
      language:language.value,
      starttime:startTime,
      submissiontime: new Date(),
      result:"",
      accuracy:"",
      username:userdata.user.name,
    }
    const saveResponse =await PostApi(data,"USERSUBMISSIONSSAVE");
   if(saveResponse.status==200){
    toast.success( `Submitted Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   }else{
    toast.error( `something went wrong!!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   }

  }
}
    return (
      <>
         {/* <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
    <Modal
show={showOutput}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
       <h4>Output</h4>
      </Modal.Header>
      <Modal.Body>
   
      <div className="col-lg-12  text-light">
            <OutputWindow outputDetails={outputDetails}  />
          </div>
          {outputDetails && <OutputDetails outputDetails={outputDetails}  />}
        </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
      <button className="btn btn-success px-4 py-2" onClick={handleSubmitCode} >Submit</button>
        <button className="btn btn-secondary text-light px-4 py-2" onClick={()=>setShowOutput(false)}>Close</button>
      </Modal.Footer>
    </Modal>
    <Modal
show={showCustomInput}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
       <h4>custom Input</h4>
      </Modal.Header>
      <Modal.Body>
   
      <CustomInput
                customInput={customInput}
                setCustomInput={setCustomInput}
              />
        </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger px-4 py-2" onClick={()=>setShowCustomInput(false)}>Ok</button>
      </Modal.Footer>
    </Modal>
         {/* <HeaderTwo /> */}
  
  
    {startCoding ?  <><div className="row p-0 m-0" style={{zIndex:"9999"}}>
          <div className="col-sm-12 col-md-4 col-lg-3 pt-3 ">
            <LanguagesDropdown onSelectChange={onSelectChange} />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-3 pt-3">
            <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
          </div>

          <div className="col-sm-12 col-md-4 col-lg-3 pt-3">
          <div style={{textAlign:'right'}}>
              <button
                onClick={()=>setShowCustomInput(true)}
                className="bg-secondary text-light px-2 py-2 rounded" style={{fontWeight:"bold",boxShadow:'4px 4px black'}}
              >
                  Custom Inputs
              </button>
              </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-3 pt-3">
          <div style={{textAlign:'right'}}>
              <button
                onClick={handleCompile}
                disabled={!code}
                className="bg-success text-light px-4 py-2 rounded" style={{fontWeight:"bold",boxShadow:'4px 4px black'}}
              >
                  {processing?'Compiling . . .': 'Execute'}
              </button>
              </div>
          </div>
        
        </div>
         <div className="row p-0 mx-0 my-3" style={{overflow:'hidden',zIndex:"9999"}}>
          <div className="col-lg-12 mt-3">
            <CodeEditorWindow
              code={code}
              onChange={onChange}
              language={language?.value}
              theme={theme.value}
            />
          </div>
  
          {/* <div className="col-lg-4 mt-3 text-light" style={{zIndex:"9999"}}>
            <OutputWindow outputDetails={outputDetails} />
            <div >
              <CustomInput
                customInput={customInput}
                setCustomInput={setCustomInput}
              />
              <div style={{textAlign:'right',marginRight:'20px'}}>
              <button
                onClick={handleCompile}
                disabled={!code}
                className="bg-light text-dark px-4 py-2 rounded" style={{fontWeight:"bold",boxShadow:'4px 4px black'}}
              >
                  {processing?'Compiling . . .': 'Execute'}
              </button>
              </div>
            </div>
            {outputDetails && <OutputDetails outputDetails={outputDetails} />}
          </div> */}
        </div> </> : <div style={{height:"560px", backgroundColor:"rgb(54,69,79)", width:"100%",display:"flex",justifyContent:"center", paddingTop:"250px", }}>
          <div>
            <button className="btn btn-success px-5 py-3 fs-4 fw-600" onClick={handleStartCoding}>Start Coding</button>
          </div>
          </div>}
  

        {/* <FooterTwo /> */}
      </>
    );
}

export default LandingTwo