import React, { Component, useState } from 'react';
import { Styles } from '../styles/courseSearch.js';

const ProgramCourseSearch=(props)=>{
    const [data,setData]=useState([])
    const handleChange=(e)=>{

       setData(e.target.value)

    }
    const searchBtn=(e)=>{
        e.preventDefault();
        // console.log("search data",data);
        props.setSearchData(data.toLowerCase())
        
    }


    
        return (
            <Styles>
                {/* Course Search */}
                <div className="course-search">
                    <h6>Search Course</h6>
                    <form>
                        <input type="text" name="search" placeholder="Search Here" onChange={handleChange} style={{backgroundColor:"white"}}/>
                        <button type="submit" onClick={searchBtn}><i className="las la-search"></i></button>
                    </form>
                </div>
            </Styles>
        )
    
}

export default ProgramCourseSearch
