import React, { useEffect, useState } from 'react'
import { Styles } from './styles/course';
import { Col, Row } from 'react-bootstrap';
import { PostApi } from '../../services/CommonService2';
import HeaderTwo from '../../components/HeaderTwo';
import FooterTwo from '../../components/FooterTwo';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Slider from 'react-slick';


function SampleProject() {
    const history = useHistory();
    const [projecttype, setProjectType] = useState([])
    const [subprojects, setSubProjects] = useState([])
    const [projecttypeid, setProjectTypeid] = useState([])
    useEffect(() => {
        getProjecttype()
        getSubProjects()

    }, [])

    const getProjecttype = async () => {
        const reqparams = {
            pageno: '-1',
            query: { status: 'Active' },
        }
        const Response = await PostApi(reqparams, 'GETPROJECTTYPE')
        setProjectType(Response.data)


    }

    const getSubProjects = async () => {
        const reqparams = {
            pageno: '-1',
            query: { status: 'Active' },
        }
        const Response = await PostApi(reqparams, 'GETSUBPROJECTS')
        setSubProjects(Response.data)
        setProjectTypeid("all")

    }


    const newData = async (data) => {
        setProjectTypeid(data.id)
        const reqparams = {
            pageno: '-1',
            query: { status: 'Active', projecttypeid: data.id }
        }
        const dataResponse = await PostApi(reqparams, 'GETSUBPROJECTS');
        setSubProjects(dataResponse.data)
        console.log("competitiveList", dataResponse.data);
    }


    const CreateProject = async (data) => {
        history.push({
            pathname: "/uploadprojects",
            state: data
        })

    }
    var settings = {
        dots: true,
        infinite: false,
        // speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
       
      };

    return (
        <>
            <HeaderTwo />
            <Styles>
                <section className="course-grid-area">
                    <div className="course-items">
                        <Row>
                            <Col md="12">
                                <div className="filter-btns text-center">
                                    <ul className="filter-btn-list list-unstyled list inline">
                                        <li className={projecttypeid == '' || projecttypeid == "all" ? "active list-inline-item" : "list-inline-item"} onClick={getSubProjects}>All Projects </li>

                                        {projecttype?.map((data, i) => (
                                            <li className={projecttypeid == data.id ? "active list-inline-item" : "list-inline-item"} key={i} onClick={() => newData(data)}>{data.type}</li>

                                        ))}
                                      
                                    </ul>
                                </div>
                                <Row>
                                    <Slider  {...settings}>
                                        {
                                            subprojects?.map((data, i) => {

                                                let imageid = 'https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                                                if (data?.image) {
                                                    imageid = data.image;
                                                } else if (data?.image_url) {
                                                    imageid = data.image_url;
                                                }
                                                else {
                                                    imageid = data.imageurl ? data.imageurl : "";
                                                }

                                                return <Col lg="4" className='px-3' key={i} >
                                                    <div className="course-item" onClick={() => CreateProject(data)}>

                                                        <div className="course-image">
                                                            <div >
                                                                <img src={imageid} className="course-image"></img>

                                                            </div>
                                                        </div>

                                                        <div className="course-content">
                                                            <h6 className="heading">{data.subprojectname}</h6>
                                                            <p className="desc">{data.description.slice(0, 100)}</p>
                                                            <div className="course-face d-flex justify-content-between">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            })
                                        }


                                    </Slider>
                                </Row>
                            </Col>

                        </Row>


                    </div>
                </section>
            </Styles>
            <FooterTwo />


        </>
    )
}

export default SampleProject