import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { PostApi } from '../services/CommonService2';
import Problembar from './components/Problembar';
const options = [
    { value: 'Latest', label: 'Latest' },
    { value: 'Accuracy', label: 'Accuracy' },
    { value: 'Submission', label: 'Submission' },
    { value: 'Difficulty', label: 'Difficulty' },
  ];
function MainContainer({subTopicSelectedTags,companySelectedTags,setSearchBarData,setSetSubTopicSelectedTags,setCompanySelectedTags,level,setLevel}) {
    const [selectedOption, setSelectedOption] = useState(null);
    const [problems, setProblems] = useState([]);
    const [searchValue, setSearchValue] = useState("")

    useEffect(()=>{
        getAllProblems()
    },[subTopicSelectedTags,companySelectedTags,searchValue,level])

    const getAllProblems = async()=>{
        var reqParams;
        
      if(searchValue ==""){

      if(level.length >0){
        if(subTopicSelectedTags.length > 0 && companySelectedTags.length ==0){
             reqParams={
                pageno: '-1',
                query: {  'subtopics.label': {$in:subTopicSelectedTags }, difficultylevel:{$in:level}}
                
            }
        }else if(subTopicSelectedTags.length == 0 && companySelectedTags.length >0){
            reqParams={
               pageno: '-1',
               query: {  'companytags.label': {$in: companySelectedTags}, difficultylevel:{$in:level}}
               
           }
        }else if(subTopicSelectedTags.length > 0 && companySelectedTags.length >0){
            reqParams={
               pageno: '-1',
               query: {  'companytags.label': {$in: companySelectedTags},
               'subtopics.label': {$in:subTopicSelectedTags },
                difficultylevel:{$in:level}
            }
               
           }
        }
        else{
          
            reqParams={
                pageno: '-1',
                 query: {difficultylevel:{$in:level}}
            }
        }
      }
      else{
        if(subTopicSelectedTags.length > 0 && companySelectedTags.length ==0){
            console.log("If executing subtopic tags")
             reqParams={
                pageno: '-1',
                query: {  'subtopics.label': {$in:subTopicSelectedTags }}
                
            }
        }else if(subTopicSelectedTags.length == 0 && companySelectedTags.length >0){
            console.log(" Else If executing Company tags",companySelectedTags)
            reqParams={
               pageno: '-1',
               query: {  'companytags.label': {$in: companySelectedTags}}
               
           }
        }else if(subTopicSelectedTags.length > 0 && companySelectedTags.length >0){
            console.log(" Else If executing Company tags",companySelectedTags)
            reqParams={
               pageno: '-1',
               query: {  'companytags.label': {$in: companySelectedTags},
               'subtopics.label': {$in:subTopicSelectedTags }
            }
               
           }
        }
        else{
            console.log("else executing")
            reqParams={
                pageno: '-1',
            }
        }
      }


      }else{
        console.log("searchValue executing",searchValue)
        reqParams={
            pageno: '-1',
            query: {  problem: {$regex: searchValue, $options: "i"}}
            
        }
      }
        
       const problemItems = await PostApi(reqParams,"COMPANYPROBLEMS")
      setProblems(problemItems.data)
    }

    const handleSearchBar=(e)=>{
        var value = e.target.value;
        setSetSubTopicSelectedTags([]);
        setCompanySelectedTags([]);
        setLevel([])
        setSearchValue(value);
        setSearchBarData(value);
    }
        return (
        <>
            <div className='bg-light p-5  h-100 m-0 '>
                <div className='row mb-4 p-0'>
                    <div className='col-lg-6'>
                    <h2 className=''>Problems</h2>
                   <div className='mt-3'>
                    <small className=''>? of 182 Problems Solved <span style={{textDecoration:"underline",marginLeft:'30px'}}>Sign-In To Track Your Progress</span></small>
                   <ProgressBar variant="success"  now={25} />
                   </div>
                   <div className='mt-3'>
                   <span className=' p-1 border rounded bg-primary text-light' style={{marginRight:"10px"}}>Submissions</span>
                    {subTopicSelectedTags.map(ele=>{
                        return(
                            <span className=' p-1 border rounded' style={{marginRight:"10px"}}>{ele}</span>

                        )
                    })}
                   
                   </div>
                    </div>
                    <div className='col-lg-6 d-flex '>
                    <div className='p-2 w-100'>
<Form.Control
              type="text"
              placeholder="Search"
              className=" mr-sm-2  d-inline" style={{height:"40px"}}
              value={searchValue}
              onChange={handleSearchBar}
            />
</div>
               {/* <div className='w-50 p-2'>
               <Select 
        placeholder="SORT: Submissions"
        onChange={setSelectedOption}
        options={options}
      />
               </div> */}


                    </div>
                </div>
                {problems.map((ele,ind,arr)=>{
                    return(
                     <Problembar ele={ele}  />
                    )
                })}

                
            </div>

        </>
    )
}

export default MainContainer